@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align: center;
  }

  /* Firefox */
  & input[type='number'] {
    -moz-appearance: textfield;
    text-align: center;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.spinner {
  margin: 10px auto;
  width: 100%;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;
  display: block;

  @media (--viewportMedium) {
    margin: 0 48px 12px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  width: 100%;
  padding: 100px 24px 60px;

  @media (--viewportMedium) {
    padding: 100px 0 60px;
  }
}

.submitButton {
  font-size: 21px;
  min-height: 65px;
  border-radius: 0;
  max-width: 226px;
}

.formContainer {
  display: flex;
  flex-direction: row;
}

.extrasContainer {
  border-bottom: 1px solid var(--customBorderGray);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0 0 40px;
  }
}

.bedroomsContainer {
  padding: 0 24px;
  border-bottom: 1px solid var(--customBorderGray);

  @media (--viewportMedium) {
    padding: 35px 0 50px;
  }
}

.headerExtras {
  font-size: 30px;
  line-height: 46px;
  font-weight: 600;
  letter-spacing: -1.25px;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 56px;
  }
}

.subTitle {
  font-size: 20px;
  margin-top: 35px;
  color: var(--customBorderGraySecond);
  letter-spacing: -0.75px;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.rowContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 0 5px;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.nameContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 1.5;
}

.inputContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 2.5;
  padding: 0 20px 0 0;
  align-items: center;
}

.numberInput {
  width: 48px;
  margin: 0 23px;
}

.numberText {
  font-size: 16px;
  line-height: 20px;
  width: 48px;
  margin: 0 23px;
  text-align: center;
  border-bottom: 3px solid var(--successColor);
}

.hideInput {
  display: none;
}

.rowText {
  font-size: 16px;
  margin: 11px 0;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.totalPriceContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 3;
}

.incrementButton,
.decrementButton {
  border-radius: 50%;
  position: relative;
  width: 25px;
  height: 25px;
  border: 2px solid var(--customBorderGraySecond);
  cursor: pointer;
}

.incrementButton::after,
.decrementButton::before {
  content: '';
  width: 14px;
  height: 2px;
  position: absolute;
  top: 9px;
  left: 4px;
  background-color: var(--customBorderGraySecond);
}

.incrementButton::before {
  content: '';
  width: 2px;
  height: 14px;
  position: absolute;
  top: 3px;
  left: 10px;
  background-color: var(--customBorderGraySecond);
}

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
  }
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-grow: 0;
    flex-basis: 619px;
    /* margin-top: 121px; */
    margin-right: 132px;
  }
}

.aspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  object-fit: cover;

  @media (--viewportLarge) {
    max-height: 203px;
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 407px;
    padding-bottom: 55px;

    /* Position */
    margin: 0 0 24px 0;
  }
}

.bookingInfoContainer {
  /* Coloring */
  background-color: var(--matterColorLight);
  border: 1px solid var(--matterColorNegative);
  border-radius: 2px;
}

.summaryTitle {
  font-size: 30px;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 49.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.detailsHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px;
  }
}

.detailsParentTitle {
  margin-bottom: 4px;

  @media (--viewportLarge) {
    margin-top: 17px;
    margin-bottom: 6px;
  }
}

.detailsTitle {
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--matterColorAnti);
  margin: 0 0 10px 0;
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    margin: 0 48px;
  }
}
