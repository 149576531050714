@import '../../styles/propertySets.css';

.root {
  margin: 40px 0 0;
  max-width: 724px;
}

.input {
  border-color: var(--matterColorAnti);
}

.inputSuccess {
  border-color: var(--matterColorAnti);
}

.inputError {
  border-color: var(--failColor);
}

.addField {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.mediaBlockInputWrapper {
  width: 100%;
}

.mediaBlockInput {
  min-height: 46px;
  margin-right: 0;
}

.mediaBlockButtonWrapper,
.deleteButton {
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  max-width: 126px;
  min-height: 46px;
  margin: 20px 0 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    margin: 37px 0 0;

    &:hover {
      color: var(--matterColorLight);
      background: var(--marketplaceColor);
    }
  }
}

.mediaBlockButtonWrapper {
  @apply --marketplaceButtonStyles;
  padding: 0 10px;
  width: auto;
  margin-left: 10px;
}

.videoWrapper {
  margin-bottom: 18px;

  & ul {
    min-height: 68px;
    padding-top: 11px;
  }
}

.deleteButton {
  width: 74px;
  flex-shrink: 0;
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  margin: 20px 0 0;

  @media (--viewportMedium) {
    margin: 37px 10px 0;

    &:hover {
      color: var(--marketplaceColor);
      background: var(--matterColorLight);
    }
  }
}

.deleteLink {
  padding-left: 6px;
  color: var(--failColor);

  @media (--viewportSmall) {
    padding-left: 20px;
  }
}

.validationMessage {
  color: var(--failColor);
  font-size: 14px;
}

.mediaList {
  list-style: none;
  margin-bottom: 0;
}

.mediaListItem {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.mediaListItem:last-child {
  padding-bottom: 0;
}

.searchItems li {
  padding: 10px;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
}

.searchItems li > img {
  width: 40px;
  height: 40px;
}

.searchItems li:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}

.optionalText {
  margin-left: 5px;
  font-size: 12px;
  line-height: 16px;
  color: var(--customPrimaryGray);
  font-weight: var(--fontWeightRegular);
}

.infoTip {
  font-size: 12px;
  line-height: 16px;
}
