.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form {
  flex-grow: 1;
}

.priceHint {
  font-size: 14px;
}
