.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  & [class*='FieldTextInput_input'] {
    border-bottom-color: var(--customPurple);
  }

  & [class*='FieldTextInput_inputError'] {
    border-bottom-color: var(--failColor);
  }

  & div[class*='FieldTextInput_root'] > label {
    padding-bottom: 8px;
  }

  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  & input[type='number'] {
    -moz-appearance: textfield;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.titleInputIcon {
  margin-top: 0;
  line-height: 16px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
}
.title {
  composes: formMargins;
  max-width: 564px;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
  max-width: 564px;
}

.location {
  max-width: 564px;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.selectDropdownWrap {
  width: 50%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.selectDropdown {
  composes: formMargins;
  flex-shrink: 0;
  width: 40%;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.groupsFourRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      padding-right: 10px;
      width: 33.3333333%;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 25%;
    }
  }
}

.groupsTwoRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 50%;
      padding-right: 10px;
    }
  }
}

.groupsThreeRows {
  composes: formMargins;

  & > ul {
    display: flex;
    justify-content: space-between;
    max-width: 564px;
  }
}

.radioButtonRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.radioItem {
  margin-left: 20px;
}

.durationFixed {
  display: flex;

  & .durationValue {
    display: flex;
    flex-direction: row-reverse;
    & input {
      max-width: 60px;
    }
  }
}

.durationFixedRadioButton {
  margin-right: 50px;
}

.maxAttendees {
  composes: formMargins;
  & input {
    max-width: 106px;
  }
}

.durationBlock {
  composes: formMargins;
}

.durationText {
  font-size: 14px;
}

.respectCovidLabel {
  font-size: 14px;
}
