.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 45%;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.priceInputsWrap {
  display: flex;
  justify-content: space-between;
}

.radioButtonRow {
  margin-bottom: 10px;
  max-width: 705px;
}

.priceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.priceCheckbox {
  margin-right: 20px;
}

.priceTable {
  margin-bottom: 120px;
}
