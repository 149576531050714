.popupSize {
  padding: 0 10px 7px 10px;
  background: var(--marketplaceColorDark);
}

.popupSize + div {
  background: var(--marketplaceColorDark);

  & button {
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      color: var(--marketplaceColor);
    }
  }
}
