@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 7px 10px;
  box-shadow: var(--boxShadowListingCardInfo);
  min-height: 100px;
  justify-content: start;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue,
.perUnit {
  /* Font */
  @apply --marketplaceH4FontStyles;
  font-size: 15px;
  line-height: 19px;
  color: var(--customGray);
  opacity: 0.5;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  padding: 0 0 0 5px;
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--customGray);
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightMedium);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 0 8px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.iconContainer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.iconRse {
  width: 30px;
  height: 30px;
}

.iconConference {
  /* position: absolute;
  bottom: 10px;
  right: 10px; */
  width: 30px;
  height: 30px;
  display: flex;
  background-color: #68098b;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 7px;
}

.conferenceImg {
  width: 60%;
  object-fit: cover;
}

.amountChildListings {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 0 0 8px;
}
