@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  color: var(--customBlue);
  margin-bottom: 22px;
  padding-bottom: 7.5px;
  border-bottom: 1px solid var(--customBlue);
  display: inline-block;

  @media (--viewportMedium) {
    font-size: 40px;
    line-height: 58px;
    font: normal normal 600 40px/58px var(--fontFamily);
  }
}

.itemsAll {
  font: normal normal 600 16px/21px var(--fontFamily);
  color: var(--customBlue);
  background-color: var(--customBlueLightTransparent);
  padding: 10px 30px;
  border-radius: var(--borderRadiusButton);
}

.items {
  margin: 0 -12px;

  @media (--viewportMLarge) {
    margin: 0 -24px;
  }
}

.itemsWrapper {
  display: flex;
  margin-top: 0px;
  flex-wrap: wrap;
  flex-direction: row;
}

.itemsCol {
  padding: 0 12px 24px;
  width: 50%;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    padding: 0 24px 24px;
  }

  @media (--viewportMLarge) {
    width: 25%;
    padding: 0 24px;
  }
}

.item {
  display: block;
  transition: var(--transitionStyleButton);
  background: url('../../assets/blue_small.jpg') no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

.itemText {
  @apply --marketplaceH2FontStyles;

  padding: 40px 12px 40px 24px;
  color: var(--matterColor);
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.23px;
  color: var(--matterColorLight);
  z-index: 3;
  width: 100%;
  margin: 0;

  @media (--viewportMedium) {
    font-size: 32px;
    line-height: 30px;
    padding: 20px 20px 20px 40px;
  }

  @media (--viewportForCard) {
    font-size: 42px;
    line-height: 41px;
  }
}

.itemsCol:last-of-type {
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-right: 0;
  }
}
