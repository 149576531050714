.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  min-width: 90px;
}

.title > label {
  min-height: 40px;
}

.vat {
  max-width: 105px;
  min-width: 105px;
}

.vat > label {
  min-height: 40px;
}

.field {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  display: inline-block;
  width: 100%;
  padding: 1rem 2rem;
  margin: 3rem 0;
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.groupsFiveRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      width: 33.3333333%;
      padding-right: 10px;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 20%;
    }
  }
}

.groupsFourRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      padding-right: 10px;
      width: 33.3333333%;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 25%;
    }
  }
}

.groupsThreeRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 50%;
      padding-right: 10px;
    }
  }

  @media (--viewportLarge) {
    & > ul > li {
      width: 33.333333%;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 16px;

  @media (--viewportMedium) {
    margin-right: 20px;
  }

  @media (--viewportLarge) {
    margin-right: 30px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > * {
    flex: 1;
  }
}

.mainBedroomInfo {
  display: flex;
  flex-direction: column;
}

.priceContainer {
  display: flex;
  flex-direction: row;
}

.addBedRoomForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border: 2px dashed #eee;
  border-radius: 0.5rem;
}

.bedroomSelect {
  min-width: 150px;
}

.bedroomSelect > label {
  min-height: 40px;
}

.capacityInput {
  margin-left: 30px;
  width: 80%;
  max-width: 195px;
}

.capacityInput > label {
  min-height: 40px;
}

.capacityInput > input {
  max-width: 98px;
}

.bedroomList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bedroomItemWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bedroomDataItemWrap {
  display: flex;
  flex-direction: row;
  min-width: 252px;
  background-color: #ede9f2;
  padding: 1rem;
  border-radius: 0.5rem;
  position: relative;
  width: 100%;
}

.bedroomDataItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bedroomDataItem:not(:first-child) {
  margin-left: 10px;
}

.addBedRoomFormFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 1rem;
}

.saveBedroomBtn {
  outline: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.currencyContainer {
  position: relative;
}

.covidInfoItem {
  display: none;
  width: 13px;
  height: 13px;
  line-height: 13px;
  padding: 0;
  display: inline-block;
  border: 1px solid #666;
  border-radius: 50px;
  color: #666;
  text-align: center;
  flex-shrink: 0;
  position: absolute;
  right: 0px;
  top: 7px;
  font-size: 75%;

  @media (--viewportMedium) {
    display: block;
  }
}

.bedroomsCount {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > * {
    flex: 1;
  }
}
