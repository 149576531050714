@import '../../styles/propertySets.css';

.root {
}

.input {
  border-bottom-color: var(--marketplaceColor);
}

.container {
  display: flex;
  flex-direction: row;
}

.decrement,
.increment {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--successColor);
  position: relative;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.decrement::before,
.increment::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 2px;
  background-color: var(--matterColorLight);
  top: 14px;
  left: 7px;
}

.increment::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 15px;
  background-color: var(--matterColorLight);
  top: 7px;
  left: 14px;
}
