@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  margin-bottom: 0;
  margin-bottom: 22px;
  text-shadow: 1px 2px 2px #555;
  font: normal normal 600 40px/48px var(--fontFamily);
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    font: normal normal 600 50px/58px var(--fontFamily);
  }
}

.titleWrapper {
  min-height: 396px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 40px 60px;
  background-image: url(./consulting.jpg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    padding: 30px 75px 60px;
  }
}

.goAll {
  font: normal normal 600 16px/58px var(--fontFamily);
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  padding: 10px 16px;
  border-radius: var(--borderRadiusButton);
  display: inline-block;
  margin: 0 auto;

  @media (--viewportMedium) {
    font: normal normal 600 20px/58px var(--fontFamily);
    margin: 0;
    padding: 10px 30px;
  }
}

.subtitle {
  & span {
    font: normal normal 300 18px/24px var(--fontFamily);
    color: var(--matterColorLight);
  }
  margin-bottom: 32px;
}
