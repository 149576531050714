.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.priceRadioButtonText {
  font-size: 20px;
}

.error {
  color: var(--failColor);
}

.title {
  font-size: 14px;
}

.priceInput {
  flex-shrink: 0;
  width: 45%;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  margin-top: 30px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.priceInputsWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.radioButtonRow {
  margin-bottom: 30px;
}

.personContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.attendeesContainer {
  display: flex;
  flex-direction: row;
  padding: 0 0 0 10px;
  align-items: flex-end;
}

.attendeesTitle {
  margin: 0;
  padding: 0 10px 0 0;
  font-size: 14px;
  min-width: 140px;
  line-height: 16px;
}

.attendeesInput {
  max-width: 50px;
  padding: 0;
}
