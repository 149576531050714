@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.container {
  margin: 0 auto 57px auto;
  padding: 0 36px;

  @media (--viewportLarge) {
    max-width: 1128px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.txInfo {
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  /* display: none; */
  width: 100%;
  position: relative;
  max-height: 268px;
  overflow-y: hidden;

  @media (--viewportLarge) {
    display: block;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 268px;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px 0;
}

.avatarWrapperProviderDesktop {
  display: flex;
  align-items: center;
  font-size: 15px;

  & > span {
    margin: 0 4px 0 0;
    font-weight: var(--fontWeightRegular);
  }
}
.avatarDesktop {
  display: flex;
  margin: 0 15px 0 0;
  color: var(--matterColorLight);
  background-image: linear-gradient(-180deg, var(--customBlueBg) 0%, var(--customDarkBlueBg) 100%);

  &:hover,
  &:focus {
    color: var(--matterColorLight);
  }
}

.currentTransactionDate {
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilyPoppins);
  text-align: right;
  font-size: 13px;
}

/* PanelHeadings subcomponent */
.headingOrder {
  padding: 30px 0;
  margin: 0;
  max-width: 100%;
  border-bottom: 1px solid var(--customBorderGray);

  & span {
    font-size: 16px;
    line-height: 1.125;
    font-weight: var(--fontWeightRegular);
    font-family: var(--fontFamilyPoppins);
  }

  & a {
    color: inherit !important;
  }
}

.headingSale {
  padding: 30px 0;
  margin: 0;
  max-width: 100%;
  border-top: 1px solid var(--customBorderGray);
  border-bottom: 1px solid var(--customBorderGray);

  & span {
    font-size: 16px;
    line-height: 1.125;
    font-weight: var(--fontWeightRegular);
    font-family: var(--fontFamilyPoppins);
  }

  & a {
    color: inherit !important;
  }
}

p.headingSale {
  border-top: none;
}

.mainTitle {
  display: block;
}

.redTitle {
  color: var(--customRed);
}

.greenTitle {
  color: var(--customGreenTwo);
}

.yellowTitle {
  color: var(--customOrange);
}

.transactionInfoMessage {
  margin: 18px 0 0 0;

  @media (--viewportMedium) {
    margin: 23px 0 0 0;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 60px 0 0 0;
  border-bottom: 1px solid var(--customBorderGray);
  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

  @media (--viewportLarge) {
    margin: 123px 0 0 0;
  }
}

.asideDesktopOpen {
  border-bottom: none;
}

.detailCard {
  @media (--viewportLarge) {
    /* position: sticky;
    top: -200px; 
    width: 409px; */
    /* background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px; */
    z-index: 1;
  }
}

.detailCardMainInfo {
  position: relative;
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  /* display: none; */
  display: block;

  @media (--viewportLarge) {
    margin: 0 0 4px 0;
  }
}

.detailCardTitleHolder {
  margin: 18px 0 4px;

  @media (--viewportSmall) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  & .detailCardTitle {
    margin: 0;
  }
}

.detailCardDateHolder {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 13px;
}

.detailCardDate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: var(--fontWeightRegular);
  margin: 10px 0;

  @media (--viewportSmall) {
    justify-content: flex-end;
    margin: 0;
  }
}

.detailCardDateItem {
  font-size: 13px;

  @media (--viewportSmall) {
    text-align: right;
  }
}

.detailCardDateItemDivider {
  margin: 0 10px;
}

.detailCardTitle {
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
  margin: 18px 0 4px;
  font-family: var(--fontFamilyPoppins);
}

.detailCardParentTitle {
  margin: 24px 0 4px;
  font-size: 18px;
  line-height: 1.4;
  color: var(--matterClor);
  font-family: var(--fontFamilyPoppins);
}

.detailCardSpaceTitle {
  font-size: 16px;
  line-height: 1.125;
  margin: 0;
  font-weight: var(--fontWeightRegular);
  font-family: var(--fontFamilyPoppins);
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

.detailCardLocation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.detailCardLocationItem {
}

.detailCardLocationValue {
  display: block;
  font-weight: var(--fontWeightRegular);
}

.transactionDuration {
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  margin-bottom: 12px;
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 0 12px 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 0;

  @media (--viewportLarge) {
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 20px 0 0 0;

  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 25px 0 150px;

  @media (--viewportLarge) {
    margin: 25px 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 0;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (--viewportLarge) {
    /* flex-direction: column; */
  }

  & button:first-child {
    /* margin: 0 12px 0 0; */

    @media (--viewportLarge) {
      /* margin: 8px 0 0 0; */

      /* Switch order in desktop layout with accept button being on the top */
      /* order: 1; */
    }
  }
}

.acceptBtn {
  @apply --marketplaceButtonStyles;
  min-width: 148px;
  width: auto;
  min-height: 39px;
  padding: 8px 16px;
  border-radius: 0;
  letter-spacing: -0.2px;
  margin: 0 0 0 35px;
  text-transform: capitalize;
}

.declineBtn {
  @apply --marketplaceButtonStylesFail;
  min-width: 148px;
  width: auto;
  min-height: 39px;
  padding: 8px 16px;
  border-radius: 0;
  margin: 0;
  letter-spacing: -0.2px;
  text-transform: capitalize;
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 0 48px 0;

  & button {
    border-radius: 0;
  }
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}

.inboxLink {
  display: block;
  background: var(--attentionColor);
  min-height: 39px;
  line-height: 39px;
  max-width: 170px;
  width: 100%;
  margin: 0 0 38px 0;
  text-align: center;
  text-decoration: none;
  color: var(--matterColorLight);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);

  &:hover,
  &:focus {
    background: var(--attentionColor);
    text-decoration: none;
    color: var(--matterColorLight);
  }
}

.detailsTitle {
  margin-bottom: 10px;
  font-size: 14px;
  color: var(--matterColorAnti);
  margin: 0 0 10px 0;
}
