@import '../../styles/propertySets.css';

.root {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 24px 24px 0;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  @media (--viewportLarge) {
    max-width: 1448px;
    padding: 30px 36px;
    margin: 0 auto;
  }

  @media (--viewportXLarge) {
    max-width: 1520px;
    padding: 0;
  }
}

.title {
  position: relative;
  color: var(--matterColorLight);
  text-transform: uppercase;
  padding-bottom: 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;

  @media (--viewportMedium) {
    padding-right: 70px;
    padding-bottom: 0;
  }

  &:after {
    @media (--viewportMedium) {
      content: '';
      position: absolute;
      right: -30px;
      top: 50%;
      background-color: var(--marketplaceColor);
      height: 1px;
      width: 40px;
    }
  }
}

.imageWrapper {
  padding-bottom: 24px;
  max-width: 116px;

  @media (--viewportMedium) {
    padding: 0;
  }
  & img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    max-height: 100px;
  }
}

div.imageBig {
  max-width: 200px;
  flex: 2 0 0;
}
