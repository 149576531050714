@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label,
.labelSelected {
  @apply --marketplaceButtonStylesSecondaryWhite;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;
  font-size: 16px;
  display: flex;
  flex-direction: row;

  &:focus {
    outline: none;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.label:hover .arrow,
.label:focus .arrow {
  border-bottom: 1px solid var(--matterColorLight);
  border-right: 1px solid var(--matterColorLight);
}

.labelSelected {
  outline: none;
  background-color: var(--customDarkBlue);
  color: var(--matterColorLight);
  border-color: transparent;
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
.labelSelected .arrow {
  border-bottom: 1px solid var(--matterColorLight);
  border-right: 1px solid var(--matterColorLight);
}
.labelSelected:hover .arrow,
.labelSelected:focus .arrow {
  border-bottom: 1px solid var(--matterColorLight);
  border-right: 1px solid var(--matterColorLight);
}
.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 300px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.arrow {
  width: 7px;
  height: 7px;
  margin: 6px 0 0 10px;
  border-bottom: 1px solid var(--matterColor);
  border-right: 1px solid var(--matterColor);
  transform: rotate(45deg);
}
