@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-bottom: 2rem;
}

.labelText {
  font-size: 14px;
  line-height: 20px;
  color: var(--matterColor);
  margin: 0;
  padding: 30px 0 24px;
}

.wrapper {
  display: flex;
  background: var(--customOrangeLight);
  padding: 16px;
  border-radius: 10px;
}

.left {
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title,
.link {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 18px;
}

.title {
  font-weight: 600;
}

.link {
  margin-bottom: 13px;
}

.link a {
  color: var(--matterColor);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.logo {
  width: 70px;
}

.input {
  display: flex;
}

.covidInfoItem {
  width: 13px;
  height: 13px;
  line-height: 13px;
  padding: 0;
  display: inline-block;
  border: 1px solid #666;
  border-radius: 50px;
  color: #666;
  text-align: center;
  flex-shrink: 0;
  position: relative;
  left: 5px;
  top: -5px;
  font-size: 80%;
}


@media (pointer: coarse), (hover: none) {
  .covidInfoItem {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }
  .covidInfoItem:focus,
  .covidInfoItem:active {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  .covidInfoItem:focus::after {
    content: attr(title);
    position: absolute;
    top: 20px;
    right: 0px;
    left: 0px;
    color: var(--matterColorLight);
    background-color: var(--matterColor);
    width: 280px;
    padding: 5px;
    z-index: 1;
    font-size: 13px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
}
