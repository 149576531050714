.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 0;
}

.error {
  color: var(--failColor);
}

.otherPackageDescription {
  margin: 20px 0 0;

  & label {
    margin-bottom: 20px;
  }
}

.submitButton {
  margin-top: 100px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin: 100px 0;
  }
}

.freeCheckbox {
  margin-left: 15px;
}

.optionsWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.optionsPart {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.additionalOptionsPart {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 500px;
}

.additionalOptionsPartItem {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  /*margin-top: 20px;*/
}
.vsWrapper {
  padding: 16px 36px;
  background-color: var(--customBackgroundColor);
}

.checkboxWithTooltip {
  position: relative;
  width: 50%;
}
.amenitiesCheckbox {
  width: 50%;
}

.checkboxPadding {
  padding: 6px;
}

.covidInfoItem {
  width: 13px;
  height: 13px;
  line-height: 13px;
  padding: 0;
  display: inline-block;
  border: 1px solid #666;
  border-radius: 50px;
  color: #666;
  text-align: center;
  flex-shrink: 0;
  position: absolute;
  right: 10px;
  top: 13px;
  font-size: 75%;
}

.vsLabel {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.internetsoeedContainer {
  padding: 16px 16px 32px;
}

.wrapperAmenityItem {
  display: flex;
  padding: 16px;
  flex-direction: column;
  max-width: 659px;
  background-color: var(--customBackgroundColor);
  & .spaceAmenityItem {
    padding: 16px;
    background-color: transparent;
    max-width: 500px;
    width: 100%;
    background-color: transparent;
  }
}
