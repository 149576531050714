.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.field {
  composes: formMargins;
  flex-shrink: 0;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.label {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 22px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
}

.radioButtonRow {
  composes: formMargins;
  display: flex;
}

.radio {
  margin-right: 80px;

  &:last-child {
    margin-right: 0;
  }
}
