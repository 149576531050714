@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondaryWhite;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;
  font-size: 16px;
  display: flex;
  flex-direction: row;

  &:focus {
    outline: none;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.label:hover .arrow,
.label:focus .arrow {
  border-bottom: 1px solid var(--matterColorLight);
  border-right: 1px solid var(--matterColorLight);
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: flex;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--customDarkBlue);
  background: var(--customDarkBlue);
  font-size: 16px;

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.arrow {
  width: 7px;
  height: 7px;
  margin: 6px 0 0 10px;
  border-bottom: 1px solid var(--matterColor);
  border-right: 1px solid var(--matterColor);
  transform: rotate(45deg);
}

.arrowSelected {
  border-color: var(--matterColorLight);
}
