@import '../../styles/propertySets.css';

.root {
  background-color: var(--matterColorLight);
}
.content {
  width: calc(100% - 48px);
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    max-width: 565px;
    margin: 0 auto 56px auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.topbar {
  z-index: 1;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background: transparent linear-gradient(4deg, #044462 0%, #010e14 100%) 0% 0% no-repeat
    padding-box;

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.wizard,
.placeholderWhileLoading {
  flex-grow: 1;
}

.emptyTitle {
  margin-bottom: 40px;
}

.emptyButton {
  @apply --marketplaceButtonStyles;
  width: auto;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}
