@import '../../styles/propertySets.css';

.root {
  position: relative;
  width: 100%;
  background-color: var(--matterColorDarkBright);

  @media (--viewportLarge) {
    border-top: none;
  }

  & svg[class*='IconSocialMediaFacebook_root'] {
    width: 14px;
    height: 25px;
    &:hover {
      fill: var(--marketplaceColorDark);
    }
  }

  & svg[class*='IconSocialMediaTwitter_root'] {
    width: 25px;
    height: 21px;
    &:hover {
      fill: var(--marketplaceColorDark);
    }
  }

  & svg[class*='IconSocialMediaYoutube_root'] {
    width: 27px;
    height: 19px;
    &:hover {
      fill: var(--marketplaceColorDark);
    }
  }

  & svg[class*='IconSocialMediaLinkedin_root'] {
    width: 26px;
    height: 25px;
    &:hover {
      fill: var(--marketplaceColorDark);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 24px;

  @media (--viewportMedium) {
    max-width: 1692px;
    padding: 60px 36px;
    margin: 0 auto;
  }

  @media (--viewportXLarge) {
    max-width: 1520px;
    padding: 60px 0;
  }
}

/* Mobile only social media links */
.someLinksMobile {
  display: none;
}

.horizLinksBarWrapper {
  background-color: #fff;
}
.horizLinksBar {
  max-width: 1400px;
  display: flex;
  padding: 50px 0;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;

  @media (--viewportLarge) {
    align-items: center;
    flex-direction: row;
  }

  & .horizListItem span {
    color: var(--customGray);
    font-size: 25px;
    line-height: 33px;
    font-weight: 600;
    margin-bottom: 16px;

    @media (--viewportMedium) {
      margin-bottom: 0;
    }
  }
  & .horizListItemTwo span {
    color: var(--customGray);
    font-size: 25px;
    line-height: 33px;
    font-weight: 600;
    margin: 0 0 16px 0;

    @media (--viewportLarge) {
      margin: 0 0 0 100px;
    }
  }
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.listTitle {
  @apply --marketplaceH3FontStyles;
  font: normal normal bold 22px/1.5 var(--fontFamily);
  color: var(--customGray);
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;

  color: var(--customGray);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
  & span {
    @media (--viewportMedium) {
      font: normal normal normal 18px/1.5 var(--fontFamily);
    }
  }
}
.linkTel {
  @apply --marketplaceH5FontStyles;
  color: #707070;
  text-decoration: none;
  font-weight: bold;
  transition: var(--transitionStyleButton);
  margin-top: 0;
  margin-bottom: 0;
  & span {
    @media (--viewportMedium) {
      font: normal normal normal 18px/1.5 var(--fontFamily);
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.socialBar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  background-color: var(--marketplaceColor);
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.socialMediaLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;

  padding-bottom: 12px;
  margin-bottom: 24px;
  @media (--viewportMedium) {
    width: 50%;
    border-right: 1px solid var(--matterColorLight);
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.visitBlog {
  text-align: center;
  width: 50%;

  & .visitBlogLink {
    font: normal normal bold 20px/25px var(--fontFamily);
    color: var(--matterColorLight);
    transition: color 0.3s ease;
    &:hover {
      text-decoration: none;
      color: var(--marketplaceColorDark);
    }
  }
}

.copyrights {
  padding: 24px 0;
  text-align: center;
  font: normal normal normal 12px/15px var(--fontFamily);
  color: var(--customGray);
}

.clientsBar {
  background-color: var(--customBlueDark);
}

.icon {
  padding: 0 25px;

  & svg {
    fill: var(--matterColorLight);
  }
}
