@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab,
.subTab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.subTab {
  @media (--viewportLarge) {
    margin-left: 44px;
  }
}

.link,
.subLink {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;

  /* Font */
  @apply --marketplaceTabNavFontStyles;

  color: var(--matterColor);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
    border-bottom-width: 3px;
  }

  @media (--viewportLarge) {
    /* width: var(--TabNav_linkWidth); */
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 6px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px;

    &:hover {
      border-bottom-width: 3px;
      border-bottom-color: var(--matterColorDark);
    }
  }
}

.subLink {
  font-size: 16px;
  line-height: 26px;
  font-family: var(--fontFamilyPoppins);
  font-weight: var(--fontWeightRegular);
  margin: 0;
  padding: 0;

  @media (--viewportLarge) {
    line-height: 40px;
    /* width: var(--SubTabNav_linkWidth); */
    /* width: 100%; */
  }
}

.selectedLink {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorDark);
  border-bottom-width: 3px;

  /* @media (--viewportLarge) {
    width: var(--TabNav_linkWidth);
    background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
    background-position: right center;
    white-space: pre-wrap;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='%23000' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  } */
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
