.popupSize {
  padding: 0 10px 7px 10px;
  background: var(--marketplaceColorDark);
}

.popupSize + div {
  background: var(--marketplaceColorDark);

  & button {
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      color: var(--marketplaceColor);
    }
  }
}

.duration {
  padding: 0 24px 24px 24px;

  & label {
    color: var(--matterColorLight);
  }

  & select {
    color: var(--matterColorLight);

    &:hover,
    &:focus {
      border-bottom-color: var(--marketplaceColor);
    }
  }
}
