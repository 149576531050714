@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

/* ================ Modal ================ */

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* This is the title for the Edit Photos component */
.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.subTitle {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: bold;
}
