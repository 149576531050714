.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding-top: 100px; */

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  padding: 11px 24px 0 24px;

  @media (--viewportLarge) {
    padding: 88px 64px 82px;
  }

  @media (--viewportMLarge) {
    padding: 88px 120px 82px;
    background-color: var(--matterColorLight);
  }
}
