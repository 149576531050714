@import '../../styles/propertySets.css';
@import '../../styles//marketplaceDefaults.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.emptyMessage {
  text-align: center;
  /* padding: 0 0 35px; */
}

.createContainer,
.createContainerCenter {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.createContainerCenter {
  align-self: center;
}

.buttonCreate,
.buttonEdit,
.buttonEditMobile {
  @apply --marketplaceButtonStyles;
  width: auto;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

.buttonCreate {
  background: transparent;
  color: var(--successColor);
  border: 1px solid var(--successColor);
  padding: 8px 20px;
}

.submitButtonNext {
  margin: 70px 0 40px;
  width: 250px;

  @media (--viewportMedium) {
    margin: 70px 0 0;
  }
}

.buttonEdit {
  padding: 5px 10px;
  font-size: 16px;
  min-height: unset;
  display: none;
  min-width: 110px;

  @media (--viewportForCard) {
    display: inline-block;
  }
}

.buttonEditMobile {
  padding: 5px 10px;
  font-size: 16px;
  min-height: unset;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;

  @media (--viewportForCard) {
    display: none;
  }
}

.spaceCardContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 0 0 30px;

  @media (min-width: 660px) {
    flex-direction: row;
    flex-basis: 50%;
    flex-wrap: wrap;
  }

  @media (--viewportForCard) {
    flex-direction: column;
    flex-basis: 100%;
  }
}

.rootSpacescard {
  flex-grow: 1;
  width: 300px;
  max-width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 7px 10px;

  @media (--viewportForCard) {
    flex-direction: row;
    width: 100%;
    max-width: unset;
  }
}

.imageContainer {
  width: 100%;
  height: 172px;
  max-height: 172px;
  background-color: var(--matterColorAnti);
  position: relative;

  @media (--viewportForCard) {
    max-width: 281px;
    height: 152px;
    max-height: 152px;
  }
}

.listingImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sizeContainer,
.optionsContainer {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;

  @media (--viewportForCard) {
    padding: 5px 20px;
  }
}

.listingTitle {
  margin: 0;
  padding: 0 0 10px;
  font-size: 18px;
  line-height: 23px;
}

.daylightContainer {
  display: flex;
  flex-direction: row;
  padding: 0 0 10px;
  align-items: center;
}

.daylightImage {
  width: 16px;
  height: 16px;
}

.listingDaylight {
  margin: 0;
  font-size: 10px;
  line-height: 13px;
  padding: 0 0 0 6px;
}

.listingSize {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  padding: 0 0 15px;
  color: var(--customBlue);
}

.listingStylesWrapper {
  display: flex;
  flex-direction: row;
}

.listingStylesContainer {
  display: flex;
  flex-direction: column;
  padding: 0 6px 0 0;

  @media (--viewportForCard) {
    padding: 0 15px 0 0;
  }
}

.listingStylesImage {
  width: 25px;
  height: 25px;
}

.listingStyleNumber {
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-align: center;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
}

.optionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 10px;
}

.optionImageContainer {
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
  display: flex;
}

.listingOptionImage {
  width: 100%;
  height: 100%;
}

.optionlabel {
  margin: 0;
  padding: 0 20px 0 0;
  font-size: 15px;
  line-height: 19px;
}

.optionlabelFree {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 17px;
  color: var(--successColor);
  position: relative;
}

.optionlabelFree:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 2px;
  top: 8px;
  left: -11px;
  transform: rotate(-45deg);
  background-color: var(--successColor);
}

.optionlabelFree:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 6px;
  top: 8px;
  left: -13px;
  transform: rotate(-45deg);
  background-color: var(--successColor);
}

.duplicateButton {
  @apply --marketplaceButtonStyles;
  width: auto;
  display: none;
  background: transparent;
  color: var(--successColor);
  border: 1px solid var(--successColor);
  padding: 5px 10px;
  margin-top: 10px;
  font-size: 16px;
  min-height: unset;
  min-width: 110px;

  @media (--viewportForCard) {
    display: inline-block;
  }
}

.removeButton {
  @apply --marketplaceButtonStylesFail;
  color: var(--customRed);
  border: 1px solid var(--customRemoveColor);
  width: auto;
  display: none;
  background: transparent;
  padding: 5px 10px;
  margin-top: 10px;
  font-size: 16px;
  min-height: unset;
  min-width: 110px;

  @media (--viewportForCard) {
    display: inline-block;
  }
}

.removeButton:hover {
  color: var(--matterColorLight);
}

.duplicateButtonMobile,
.removeButtonMobile {
  @apply --marketplaceButtonStyles;
  padding: 5px 10px;
  font-size: 16px;
  min-height: unset;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 50px;
  width: auto;
  /* background: transparent;
  color: var(--successColor);
  border: 1px solid var(--successColor); */

  @media (--viewportForCard) {
    display: none;
  }
}

.removeButtonMobile {
  @apply --marketplaceButtonStylesFail;
  right: 5px;
  top: 95px;
  background: var(--customRemoveColor);
  border: 1px solid var(--customRemoveColor);
  padding: 5px 10px;
  font-size: 16px;
  min-height: unset;
  display: inline-block;
  position: absolute;
  width: auto;

  @media (--viewportForCard) {
    display: none;
  }
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  padding-top: 36px;
  text-align: center;
}

.modalButtonsWrapper {
  padding-top: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.cancelListingDelete {
  color: var(--matterColorAnti);
  cursor: pointer;
  width: 50%;
  text-align: right;
  padding-right: 64px;

  &:hover {
    color: var(--matterColor);
  }
}
