@import '../../styles/propertySets.css';

.root {
}

.fieldsWhereInput {
  height: auto;
  border: none;
  padding: 0;
}

.fieldsWhereRoot {
  padding: 10px 40px 10px 20px;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);
}

.fieldsWhereIcon {
  align-items: center;
  margin-right: 16px;
  border: none;
}

.searchContainer {
  max-width: 469px;
  position: relative;
  padding-bottom: 14px;
  width: 100%;

  @media (--viewportMedium) {
    padding-bottom: 25px;
  }

  & .leftBar {
    position: absolute;
    bottom: 0;
    left: -14px;
    transform: translateX(-100%);

    @media (--viewportMedium) {
      left: -25px;
    }
  }

  & .rightBar {
    position: absolute;
    bottom: 0;
    right: -14px;
    transform: translateX(100%);

    @media (--viewportMedium) {
      right: -25px;
    }
  }
}

.fieldsWhat,
.fieldsWhatDark {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 14px 40px;
  border-radius: var(--borderRadiusButton);
  background-color: #ffffff26;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  & .fieldsWhatLabel {
    width: 100%;
    font: normal normal 600 18px/27px var(--fontFamily);
    color: var(--matterColorLight);
    flex: 1 0 100%;
  }

  & .fieldsWhatLabelDark {
    width: 100%;
    font: normal normal 600 18px/27px var(--fontFamily);
    flex: 1 0 100%;
    color: var (--matterColor);
  }
}

.fieldsWhatDark {
  background-color: #1d1d1d26;
}

.checkboxWrapper {
  padding: 6px;
  flex: 0 0 50%;
  /* margin-left: -6px; */
}

.checkbox {
  background-color: var(--matterColorLight);
  color: var(--customGray);
  text-align: center;
  /* padding: 5px; */
  transition: background-color 0.3s ease;
  border-radius: var(--borderRadiusButton);
  cursor: pointer;

  & > span {
    display: block;
    width: 100%;
    height: 100%;
  }

  & label {
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0;
    cursor: pointer;
  }

  & label span {
    margin: 11px 0;
  }

  & svg {
    display: none;
  }

  &.checked {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  /* & [class*='FieldRadioButton_radioButtonWrapper'] {
    margin: 0;
  } */

  & .locationRadioButton {
    margin: 0;
  }
}

.fieldsWhere,
.fieldsWhereDark {
  position: relative;
  display: flex;
  padding: 16px 40px;
  border-radius: var(--borderRadiusButton);
  background-color: #ffffff26;
  margin-bottom: 20px;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.fieldsWhereDark {
  background-color: #1d1d1d26;
}

.fieldsWhereLabel,
.fieldWhereLabelDark {
  font: normal normal 600 18px/27px var(--fontFamily);
  color: var(--matterColorLight);
  white-space: nowrap;
  margin-bottom: 10px;

  @media (--viewportMedium) {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

.fieldWhereLabelDark {
  color: var(--matterColor);
}

.fieldsWhereRoot {
  border-radius: var(--borderRadiusButton);
  background-color: #fff;
}

.fieldsWhereIcon {
  background-color: transparent;
}

.formBottom {
  & button {
    padding: 8px 0;
    border-radius: var(--borderRadiusButton);
    font-size: 20px;
    font-weight: 700;

    @media (--viewportMedium) {
      padding: 14px 0;
    }
  }
}

.iconResetSearch {
  top: 25px;
  right: 60px;
  position: absolute;
  transform: rotate(45deg);
  color: #4a4a4a;
  font-size: 22px;
  font-weight: 700;
  z-index: 2;
  cursor: pointer;
}

.searchContainer.horizontal {
  max-width: 100%;
  background: transparent;
  position: relative;
  width: 100%;
  padding: 24px 0;

  @media (--viewportMedium) {
    padding: 0 0 24px;
  }

  & .leftBar {
    display: none;
  }

  & .rightBar {
    display: none;
  }

  & .checkboxWrapper {
    flex: 1 0 0;
    padding: 6px;
    margin-left: 0;
    height: auto;
  }

  & .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  & .searchForm {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 36px;
    max-width: 1520px;
    margin: 0 auto;

    @media (--viewportMedium) {
      flex-direction: row;
      margin: 0 0 10px;
      flex-wrap: wrap;
    }
    @media (--viewportLarge) {
      flex-wrap: nowrap;
      margin: 0 auto;
    }
  }

  & .fieldsWhereRoot {
    padding: 5px 40px 5px 20px;
    width: 100%;
  }

  & .fieldsWhat {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 14px 16px;

    @media (--viewportMedium) {
      margin: 0 0 10px;
      width: 100%;
    }
    @media (--viewportLarge) {
      flex: 1 0 58%;
      padding: 8px 16px;
      margin: 0 8px 0 0;
      width: auto;
    }

    & .fieldsWhatLabel {
      display: none;
    }

    & .fieldsWhatLabelDark {
      display: none;
    }
  }

  & .iconResetSearch {
    top: 18px;
    right: 33px;
  }

  & .fieldsWhere {
    padding: 14px 16px;
    @media (--viewportMedium) {
      flex-direction: row;
      margin: 0 10px 0 0px;
      flex-wrap: wrap;
      flex: 1 0 80%;
      height: auto;
    }
    @media (--viewportLarge) {
      flex: 1 0 27%;
      margin: 0 22px 0 0;
      flex-wrap: nowrap;
      align-items: center;
    }

    & .fieldsWhereLabel {
      display: none;
    }

    & .fieldWhereLabelDark {
      display: none;
    }
  }

  & .formBottom {
    @media (--viewportLarge) {
      flex: 1 0 10%;
    }
    & button {
      padding: 8px 4px;

      @media (--viewportMedium) {
        height: 100%;
      }

      @media (--viewportLarge) {
        padding: 20px 38px;
        font-size: 16px;
      }
    }
  }
}
