@import '../../styles/propertySets.css';

.root {
  font-size: 14px;
}

.pending {
  display: flex;
  align-items: center;
  color: var(--attentionColor);

  & svg {
    margin: 0 10px 0 0;
  }

  & svg path {
    fill: var(--attentionColor);
  }
}
