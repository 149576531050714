.profilePage {
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 88px 0 0 150px;
  }
}

.tab > a {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);
  padding: 10px;

  @media (--viewportLarge) {
    font-size: 20px;
    font-weight: var(--fontWeightRegular);
    line-height: 56px;
    margin: 0;
    padding: 0;
  }
}
