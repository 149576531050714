@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-image: url(home.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--customBlueDark);
    opacity: 0.9;
  }
}

.heroContent {
  display: flex;
  justify-content: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
    margin: 0 24px 0 24px;
    justify-content: space-between;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: 1448px;
    width: 100%;
    padding: 0 36px 0 36px;
    justify-content: space-around;
  }
}

.heroMainTitle {
  display: none;
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  position: relative;

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    display: block;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    font: normal normal 600 50px/58px var(--fontFamily);
    max-width: 496px;
    margin-bottom: 1em;
  }

  &:before {
    content: '';
    position: absolute;
    border-top: 5px solid #48c39c;
    border-left: 5px solid #48c39c;
    width: 38px;
    height: 38px;
    left: -16px;
    top: -16px;
  }
}

.heroSubTitle {
  display: none;
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorLight);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    display: block;
    font-size: 25px;
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  /* composes: animation; */

  /* animation-delay: 0.8s; */

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
