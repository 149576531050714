@import '../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  /* Layout for child components */

  &.transparent {
    background: transparent;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1490px;
  padding: 30px 36px;
  margin: 0 auto;
  height: auto;
  align-items: center;
  box-shadow: var(--boxShadowLight);

  @media (--viewportMedium) {
    padding: 30px 15px;
  }

  @media (--viewportLarge) {
    padding: 30px 36px;
  }
}

/* logo */
.logoLink {
  flex-shrink: 0;
  padding: 6px 24px;
  height: 100%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 40px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.createListing {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 40px;
  color: var(--matterColor);
  border-bottom: 4px solid transparent;

  &:hover {
    color: var(--matterColorDark);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px;
  }

  @media (--viewportLarge) {
    margin-right: 40px;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
  display: flex;

  & svg {
    fill: var(--matterColorLight);
  }
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: calc(50% - 5px);
  right: 20px;

  /* Style: red dot */
  background-color: var(--attentionColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

.username {
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColorLight);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  margin-right: 40px;
  border-bottom: 4px solid transparent;

  &:after {
    content: '';
    position: absolute;
    right: -2px;
    top: 9px;
    width: 9px;
    height: 9px;
    border-right: 2px solid var(--matterColorLight);
    border-bottom: 2px solid var(--matterColorLight);
    transform: rotate(45deg);
    border-radius: 1px;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  width: 33px;
  height: 33px;
  margin-right: 16px;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  border-bottom: 4px solid transparent;
  color: var(--matterColorLight);
  font: normal normal normal 16px/20px var(--fontFamily);
  border-right: 1px solid var(--matterColorLight);

  &:hover {
    color: var(--matterColorDark);
  }
}

.loginLink {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  border-bottom: 4px solid transparent;
  margin: 0;

  color: var(--matterColorLight);
  font: normal normal 600 16px/20px var(--fontFamily);

  &:hover {
    color: var(--matterColorDark);
  }
}

.login span {
  font-weight: 600;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.listYourServices {
  padding: 10px 14px;
  border: 1px solid var(--matterColorLight);
  color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  font: normal normal normal 16px/20px var(--fontFamily);
  margin-right: 10px;
  @media (--viewportLarge) {
    margin-right: 75px;
    padding: 10px;
  }
  @media (--viewportXLarge) {
    margin-right: 100px;
    padding: 10px 14px;
  }
}

.corporateAccounts span {
  font: normal normal bold 16px/20px var(--fontFamily);
  color: var(--matterColorLight);
  text-decoration: none;
}

.authGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.transparent {
  background-color: #fff;
}

.notificationCountMarker {
  margin-left: 8px;
  border-radius: 50%;
  background-color: var(--attentionColor);
  width: 19px;
  height: 19px;
  padding: 2px;
  text-align: center;
  color: var(--matterColorLight);
  font-size: 12px;
  line-height: 16px;
}
