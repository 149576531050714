@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}

.longInfo {
  flex-wrap: wrap;
}

.filters {
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
}

.searchResultSummary {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.noSearchResults {
  @apply --marketplaceH4FontStyles;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  @apply --marketplaceH4FontStyles;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  @apply --marketplaceButtonStylesSecondaryWhite;
  @apply --marketplaceSearchFilterLabelFontStyles;

  display: inline-block;
  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;
  font-size: 16px;
  display: inline-flex;
  flex-direction: row;

  &:focus {
    outline: none;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelClosed:hover .arrow,
.searchFiltersPanelClosed:focus .arrow {
  border-bottom: 1px solid var(--matterColorLight);
  border-right: 1px solid var(--matterColorLight);
}

.searchFiltersPanelOpen {
  @apply --marketplaceButtonStylesSecondaryWhite;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;
  font-size: 16px;
  display: inline-flex;
  flex-direction: row;
}

.searchFiltersSelected {
  background-color: var(--customDarkBlue);
  color: var(--matterColorLight);
  text-decoration: none;
  box-shadow: var(--boxShadowFilterButton);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.searchFiltersPanelOpen:hover .arrow,
.searchFiltersPanelOpen:focus .arrow,
.searchFiltersSelected .arrow {
  border-bottom: 1px solid var(--matterColorLight);
  border-right: 1px solid var(--matterColorLight);
}

.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 24px;
}

.mapSwitchHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.showMapText {
  padding: 0 20px 0 0;
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

.arrow {
  width: 7px;
  height: 7px;
  margin: 6px 0 0 10px;
  border-bottom: 1px solid var(--matterColor);
  border-right: 1px solid var(--matterColor);
  transform: rotate(45deg);
}
