@import '../../styles/propertySets.css';

.root {
}

.titleWrapper {
  display: flex;
  margin-bottom: 60px;
  flex-direction: column;
  align-items: flex-start;

  @media (--viewportMedium) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.title {
  @apply --marketplaceH2FontStyles;
  margin-bottom: 24зч;
  margin-top: 0;
  padding-bottom: 7.5px;
  font-size: 20px;
  line-height: 25px;
  color: var(--customBlue);
  border-bottom: 1px solid var(--customBlue);

  @media (--viewportMedium) {
    margin-bottom: 0;
    font: normal normal 600 30px/45px var(--fontFamily);
  }
}

.goAll {
  font: normal normal 600 16px/21px var(--fontFamily);
  color: var(--customBlue);
  background-color: var(--customBlueLightTransparent);
  padding: 10px 30px;
  border-radius: var(--borderRadiusButton);
}

.locations {
  margin: 0 -24px;

  @media (--viewportLarge) {
    margin: 0 -32px;
  }
}

.locationsWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.location {
  margin-bottom: 24px;
  padding: 0 24px;
  flex: 1 0;
  transition: var(--transitionStyleButton);
  overflow: hidden;
  border-radius: var(--borderRadiusMedium);
  cursor: pointer;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0 32px;
  }
}
