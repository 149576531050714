.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  grid-auto-rows: 1fr;
  padding: 0 24px 96px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 96px 36px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCardWithoutMap {
  @media (--viewportMedium) {
    flex-basis: calc(50% - 12px);
    margin-right: 24px;
  }

  @media (--viewportLarge) {
    flex-basis: calc(33.33% - 24px);
    margin-right: 24px;
  }

  @media (--viewportMLarge) {
    flex-basis: calc(25% - 24px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1400px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportForCard) {
    margin-right: 0;
  }
}

.listingCardWithoutMap:nth-of-type(2n) {
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    margin-right: 0;
  }
}
.listingCardWithoutMap:nth-of-type(3n) {
  @media screen and (min-width: 1024 px) and (max-width: 1280px) {
    margin-right: 0;
  }
}
.listingCardWithoutMap:nth-of-type(4n) {
  @media (--viewportMLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: var(--matterColorLight);
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
