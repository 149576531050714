@import '../../styles/propertySets.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
}

.filtersWrapper {
}

.header,
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  position: relative;
}

.header {
  border-bottom: 1px solid var(--matterColorNegative);
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

.resetAllButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--customBorderGraySecond);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--customBorderGraySecond);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  /* margin-left: 48px; */

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.applyButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  border-radius: 5px;

  /* Layout */
  margin: 0 0 0 19px;
  padding: 4px 13px;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    background-color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}

.verticalLine {
  width: 1px;
  background-color: var(--customGray);
  margin: 0 10px;
}
