@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  white-space: nowrap;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 25px;
  color: var(--customBlue);
  margin-bottom: 22px;
  padding-bottom: 7.5px;
  border-bottom: 1px solid var(--customBlue);
  display: inline-block;

  @media (--viewportMedium) {
    display: block;
    font: normal normal 600 40px/58px var(--fontFamily);
  }
}

.singleNews {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  cursor: pointer;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.singleNews:last-child {
  margin-bottom: 0;
}

.newsImage {
  position: relative;
  width: 100%;
  min-width: 200px;
  height: auto;
  padding-bottom: 100%;
  margin-bottom: 24px;
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;

  & img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (--viewportLarge) {
    min-width: 1px;
    width: 86px;
    height: 86px;
    margin-right: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.newsTitle {
  & span {
    font: normal normal medium 18px/29px var(--fontFamily);
    color: var(--customBlue);
  }
  margin-bottom: 10px;
}

.newsSubtitle span {
  font: normal normal normal 18px/29px var(--fontFamily);
  color: var(--customBlue);
}

.popLink {
  position: relative;
  flex: 1 0 0;
  padding: 24px 50px;
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  max-height: 100%;
  min-height: 200px;
  justify-content: space-between;
  cursor: pointer;

  @media (--viewportLarge) {
    max-height: 200px;
  }
}

.popLink:last-child {
  margin-bottom: 0;
}

.popLinkBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.popLinkTitle {
  font-size: 32px;
  line-height: 32px;
  color: var(--matterColorLight);
  position: relative;
  z-index: 1;

  @media (--viewportMedium) {
    font: normal normal bold 46px/46px var(--fontFamily);
  }
}

.popLinkButton {
  align-self: flex-end;
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  padding: 12px 30px;
  border-radius: var(--borderRadiusButton);
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.newsWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0;
  margin: 0;
  align-items: flex-start;

  @media (--viewportMedium) {
    flex-direction: row;
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1672px;
    padding: 0 36px 0 36px;
    margin: 0 auto 83px auto;
    align-items: stretch;
  }
}

.news {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;
  background-color: var(--customLightestGray);
  order: 2;
  align-items: flex-start;

  @media (--viewportMedium) {
    width: 40%;
    justify-content: space-between;
    order: 1;
    border-radius: var(--borderRadiusMedium);
    margin-right: 20px;
  }

  @media (--viewportMLarge) {
    width: 50%;
    justify-content: space-between;
    order: 1;
    border-radius: var(--borderRadiusMedium);
    margin-right: 20px;
  }
}

.popularLinks {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 40px;
  order: 1;
  padding: var(--LandingPage_sectionMarginTop) 24px 34px 24px;
  width: 100%;

  @media (--viewportMedium) {
    order: 2;
    width: 60%;
    margin: 0;
    padding: 0;
    padding-left: 40px;
  }

  @media (--viewportLarge) {
    justify-content: space-between;
  }

  @media (--viewportMLarge) {
    width: 50%;
    padding-left: 80px;
  }
}

.covidPrivacy {
  background-color: var(--customYellow);
  display: flex;
  flex-direction: column;
  min-height: 200px;

  &:hover {
    text-decoration: none;
  }

  @media (--viewportLarge) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.covidTitle {
  padding: 6px 8px 8px;
  position: relative;
  font-style: 20px;
  line-height: 24px;
  color: var(--customBlue);
  margin-bottom: 16px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    border-top: 2px solid var(--customBlue);
    border-left: 2px solid var(--customBlue);
    width: 22px;
    height: 22px;
  }

  @media (--viewportMedium) {
    padding: 16px;
    font: normal normal 600 39px/39px var(--fontFamily);
    margin-bottom: 0;
    &::before {
      border-top: 5px solid var(--customBlue);
      border-left: 5px solid var(--customBlue);
      width: 43px;
      height: 43px;
    }
  }
}

.covidImage {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ffffffa3;
  border: 1px solid var(--customBlue);
  & img {
    width: 24px;
    height: 24px;
  }

  @media (--viewportMedium) {
    width: 64px;
    height: 64px;
    border: 2px solid var(--customBlue);

    & img {
      width: 32px;
      height: 32px;
      margin-bottom: 4px;
    }
  }

  @media (--viewportMLarge) {
    width: 85px;
    height: 85px;
    & img {
      width: 47px;
      height: 47px;
      margin-bottom: 8px;
    }
  }
}

.covidRules {
  display: flex;
  justify-content: space-between;
}

.covidRule {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 6px;
  color: var(--customBlue);
  text-transform: uppercase;
  text-align: center;
  font-size: 8px;
  line-height: 24px;
  font-weight: 600;

  @media (--viewportMedium) {
    font-size: 12px;
  }

  @media (--viewportMLarge) {
    padding: 0 8px;
    font-size: 18px;
  }
}
