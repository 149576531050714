.root {
  background-color: var(--matterColorLight);
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: auto;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 440px;
    height: auto;
    max-height: none;
  }

  @media (--viewportMLarge) {
    min-height: 497px;
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding: 24px 24px 0;

  & h1 {
    font-size: 32px;
    line-height: 40px;
  }

  @media (--viewportMedium) {
    padding-top: 100px;
    margin-top: -140px;
  }

  @media (--viewportMLarge) {
    & h1 {
      font-size: 35px;
      line-height: 1.3em;
    }
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 34px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1672px;
    padding: 0 36px 0 36px;
    margin: 0 auto 83px auto;
  }
}

.wideSection {
  margin: 0 auto;
  padding: 0;
}

.desktopOnly {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.sectionActivities {
  background-color: var(--customBlue);
  margin-bottom: 60px;

  & .sectionContent {
    margin: 0 auto;
    padding: var(--LandingPage_sectionMarginTop) 24px 60px 24px;

    @media (--viewportLarge) {
      padding: 44px 36px 60px;
    }
  }
}

.imageBackground {
  background-image: url(../../components/SectionComments/images/streets.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
