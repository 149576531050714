@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH2FontStyles;
  padding: 0;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 25px;
  margin-top: 0;

  text-align: center;
  font: normal normal 700 20px/25px var(--fontFamily);
  color: var(--customBlue);

  @media (--viewportMedium) {
    margin-bottom: 70px;
    font-size: 50px;
    line-height: 58px;
    padding-bottom: 5px;
  }
}

.servicesAll {
  font: normal normal 600 16px/21px var(--fontFamily);
  color: var(--customBlue);
  background-color: var(--customBlueLightTransparent);
  padding: 10px 30px;
  border-radius: var(--borderRadiusButton);
}

.services {
  margin: 0 -24px;
}

.servicesWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.servicesCol {
  position: relative;
  padding: 0 24px 24px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    width: 50%;
    &:last-child {
      margin-bottom: 24px;
    }
  }

  @media (--viewportMLarge) {
    width: 25%;
    padding: 0 24px;
  }
}

.service {
  display: flex;
  height: 100%;
  width: 100%;
  margin-top: 0;
  position: relative;
  transition: var(--transitionStyleButton);
  overflow: hidden;
  border-radius: var(--borderRadiusMedium);
  cursor: pointer;

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }

  & div[class*='ImagedLink_imageWrapper'] {
    position: relative;
    width: 100%;
    min-height: 218px;

    &::before {
      content: '';
      background-color: #00000022;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
    }
  }

  & .serviceText {
    @apply --marketplaceH2FontStyles;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 16px;
    margin: 0;
    left: 0;
    right: 0;
    color: var(--matterColor);
    font: normal normal bold 42px/41px var(--fontFamily);
    letter-spacing: -0.23px;
    color: var(--matterColorLight);
    text-shadow: var(--boxShadowPopupLight);
    z-index: 3;
    width: 100%;

    & span {
      display: block;
      text-align: center;
    }

    @media (--viewportMedium) {
      font-size: 32px;
      padding: 25px;
    }

    @media (--viewportForCard) {
      font-size: 42px;
    }
  }
}

.servicesCol:nth-child(2) .serviceText {
  max-width: 360px;
  @media (--viewportMedium) {
    max-width: 290px;
  }

  @media (--viewportForCard) {
    max-width: 360px;
  }
}

.servicesCol:nth-child(4) .serviceText {
  max-width: 250px;
  @media (--viewportMedium) {
    max-width: 200px;
  }

  @media (--viewportForCard) {
    max-width: 250px;
  }
}

.servicesCol:nth-child(3) .serviceText {
  max-width: 200px;
  @media (--viewportMedium) {
    max-width: 160px;
  }

  @media (--viewportForCard) {
    max-width: 200px;
  }
}

.servicesCol:nth-child(2) .serviceText,
.servicesCol:nth-child(3) .serviceText,
.servicesCol:nth-child(4) .serviceText {
  left: 50%;
  transform: translate(-50%, -50%);
}

.servicesCol:nth-child(2) .serviceText span,
.servicesCol:nth-child(3) .serviceText span,
.servicesCol:nth-child(4) .serviceText span {
  text-align: left;
}

.servicesCol:last-of-type {
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.iconConference {
  position: absolute;
  bottom: 34px;
  right: 38px;
  width: 37px;
  height: 37px;
  display: flex;
  background-color: #68098b;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  /* margin: 0 0 0 7px; */

  @media (--viewportMLarge) {
    bottom: 10px;
  }
}

.conferenceImg {
  width: 100%;
  max-width: 25px;
  object-fit: cover;
}
