@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin: 0 0 40px;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.tabNav {
  @media (--viewportLarge) {
    padding: 0;
    margin: 0;
  }
}

.tab {
  &:first-child {
    padding: 0 10px 0 0;
  }

  @media (--viewportLarge) {
    &:first-child {
      padding: 0;
    }
  }
}

.tab > a {
  font-size: 16px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportLarge) {
    font-size: 20px;
    font-weight: var(--fontWeightRegular);
    line-height: 56px;
    margin: 0;
    padding: 0;
  }
}
