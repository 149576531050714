.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
  padding: 0 25px 0 0;
  min-width: 90px;
}

.title > label {
  min-height: 40px;
  font-size: 18px;
}

.vat > label {
  min-height: 40px;
}

.field {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.groupsFiveRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      width: 33.3333333%;
      padding-right: 10px;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 20%;
    }
  }
}

.groupsFourRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      padding-right: 10px;
      width: 33.3333333%;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 25%;
    }
  }
}

.groupsThreeRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 50%;
      padding-right: 10px;
    }
  }

  @media (--viewportLarge) {
    & > ul > li {
      width: 33.333333%;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 16px;

  @media (--viewportMedium) {
    margin-right: 20px;
  }

  @media (--viewportLarge) {
    margin-right: 30px;
  }
}

.mainBedroomInfo {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}

.additionalBedroomInfo {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0 30px;

  @media (--viewportMedium) {
    padding: 10px 0 0;
  }
}

.bedroomSelect {
  min-width: 150px;
  padding: 0 25px 0 0;
}
.bedroomSelect > label {
  min-height: 40px;
}

.decriptionField {
  width: 60%;
}

.capacityInput {
  margin-left: 30px;
  width: 96%;
}

.bedroomItemWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.bedroomDataItemWrap {
  display: flex;
  flex-direction: row;
  min-width: 252px;
  background-color: #ede9f2;
  padding: 35px 15px 15px;
  /* margin-right: 10px; */
  border-radius: 25px;
  position: relative;
  width: 100%;
  max-width: 640px;
}

.bedroomDataItem {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bedroomDataItem:not(:first-child) {
  margin-left: 10px;
}

.saveBedroomBtn {
  /* max-height: 35px;
  outline: none;
  border: none;
  color: #E02020;
  font-weight: bold; */

  max-height: 35px;
  outline: none;
  border: none;
  color: #e02020;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
