@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.popupSize {
  padding: 0 0 17px;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;
  font-size: 16px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.rowInput {
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  align-items: flex-end;
}

.inputLabel {
  margin: 0;
  padding: 0;
  line-height: 20px;
  color: var(--matterColor);
}

.labelWithPadding {
  padding: 0 0 10px;
}

.inputField,
.inputFieldDecrementIncrement {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  font-size: 16px;
  width: 50px;
  text-align: center;
  border-bottom-color: var(--marketplaceColor);
  border-bottom-width: 3px;
  margin: 0 0 0 35px;

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.inputFieldDecrementIncrement {
  margin: 0 10px;
}

.rowInputContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.inputContainer {
  padding: 10px 0 0 20px;
}

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  justify-content: space-between;
}

.icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  /* background: var(--customOrange); */
  margin: -8px 0 0 0;
}

.switchHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bottomBorder {
  border-bottom: 1px solid var(--customBorderGray);
  padding: 12px 30px;
}

.info {
  width: 15px;
  height: 15px;
  line-height: 13px;
  padding: 0;
  border: 1px solid #666;
  border-radius: 50px;
  color: #666;
  text-align: center;
  flex-shrink: 0;
  font-size: 80%;
  margin: 0 0 0 10px;
}

@media (pointer: coarse), (hover: none) {
  .info {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }
  .info:focus,
  .info:active {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  .info:focus::after {
    content: attr(title);
    position: absolute;
    top: 20px;
    right: 0;
    color: var(--matterColorLight);
    background-color: var(--matterColor);
    width: 280px;
    padding: 5px;
    z-index: 1;
    font-size: 13px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
}
