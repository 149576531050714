@import '../../styles/propertySets.css';

.root {
}

.input {
  padding: 5px 0 5px 0;
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--attentionColor);
  border-radius: 0;

  @media (--viewportMedium) {
    padding: 4px 0 2px 0;
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.inputsWrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;

  @media (--viewportMedium) {
    margin: 0 -10px;
  }
}

.inputItem {
  width: 100%;
  margin-bottom: 20px;
  padding: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
    width: 33.3333333%;
    padding: 0 10px;
  }
}
