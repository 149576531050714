@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItemGroup {
  padding: 1rem 0;
  border-bottom: 1px solid var(--customBorderGray);
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding: 1rem 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.lineItemTotalVat {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemListingTotalVat {
  composes: lineItemTotalVat;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}
.itemLabel {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  margin: 0 0 0 10px;
  font-weight: var(--fontWeightSemiBold);
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  font-size: 0.8em;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
}

.showMore {
  display: flex;
  align-items: center;
  vertical-align: center;
  font-size: 14px;
  color: var(--customGray);
  justify-content: center;
}

.readMoreIcon {
  width: 13px;
  height: 17px;
  margin-left: 27px;
}

.justShowPrice {
  font: normal normal 600 24px/32px IBM Plex Sans;
  margin-bottom: 12px;
}

.bookingPeriodNewStyle {
  display: flex;
  align-items: center;
}

.hidenItem {
  display: none;
}

.useNewStyle {
  & .lineItemsHolder {
    /* border-top: 1px solid var(--customBorderGray); */
    margin-top: -1px;
    background: var(--matterColorBright);
  }

  & .lineItemGroup {
    border-bottom: 1px solid var(--customBorderGray);
  }

  & .lineItem:first-child {
    border-bottom: none;
  }

  & .itemLabel {
    font-size: 14px;
    line-height: 20px;
  }

  & .totalLabel {
    font-weight: var(--fontWeightRegular);
  }

  & .itemValue {
    font-size: 16px;
    letter-spacing: -0.2px;
    color: var(--matterColor);
    font-weight: var(--fontWeightSemiBold);
  }

  & .totalPrice {
    font-weight: var(--fontWeightSemiBold);
  }

  & .totalDivider {
    display: none;
  }

  & .lineItemTotal {
    padding: 10px 0 26px 0;

    @media (--viewportSmall) {
      padding: 10px 0 26px 0;
    }
  }

  & .lineItemExtra {
    border-top: none;
    border-bottom: 1px solid var(--customBorderGray);
  }
}

.lineItemExtra {
  display: block;
  border-bottom: none;
}

.lineItemExtraHolder {
  padding-left: 0;

  @media (--viewportSmall) {
    /* padding-left: 30%; */
  }
}

.lineItemExtraItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .itemLabel {
    font-weight: var(--fontWeightRegular);
  }

  & .itemValue {
    font-weight: var(--fontWeightSemiBold);
  }
}

.lineItemExtraTitle {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.totalPriceHolder {
  width: 100%;
  margin-left: auto;

  @media (--viewportSmall) {
    max-width: 300px;
  }

  & .lineItemTotal {
    padding-left: 0;
  }
}

.seeMore {
  width: 200px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0;
  font-weight: var(--fontWeightRegular);
  cursor: pointer;

  & .readMoreIcon {
    margin-left: 10px;
  }
}

.seeLess {
  composes: seeMore;

  & .readMoreIcon {
    transform: rotate(180deg);
  }
}

.standartPrice {
  color: var(--matterColorAnti);
  text-decoration: line-through;
  margin-right: 2px;
}
