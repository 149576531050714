@import '../../styles/propertySets.css';

.root {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-right: 63px;
    margin-bottom: 0;
    margin-top: 0;
  }
}

.root:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.linkImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--borderRadiusMedium);
  object-fit: cover;
  margin-bottom: 21px;
}

.linkText {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;
  color: var(--matterColor);

  @media (--viewportMedium) {
    font: normal normal 600 22px/22px var(--fontFamily);
  }
}
