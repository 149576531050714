@import '../../styles/propertySets.css';

.root {
}

.tableWrapper {
  margin: 0 -12px;

  @media (--viewportMedium) {
    margin: 0 -30px;
  }
}

.input {
  padding: 5px 0 5px 0;
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--attentionColor);
  border-radius: 0;

  @media (--viewportMedium) {
    padding: 4px 0 2px 0;
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.inputsWrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -10px;
}

.inputItem {
  width: 100%;
  margin-bottom: 20px;
  padding: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
    width: 33.3333333%;
    padding: 0 10px;
  }
}

.table {
  width: 100%;

  & > * {
    width: 100%;
  }
}

.tableRow {
  & > td:nth-child(2),
  & > th:nth-child(2) {
    display: none;
  }

  & > td {
    padding: 12px;
  }

  & > th {
    font-size: 14px;
    line-height: 20px;
    padding: 0 12px;
  }

  @media (--viewportMedium) {
    & > td:nth-child(2),
    & > th:nth-child(2) {
      display: table-cell;
    }

    & > td {
      padding: 30px;
    }

    & > th {
      padding: 16px 30px 0;
    }
  }
}

.checkbox {
  /* white-space: nowrap; */
}

.coloredBackground {
  background-color: var(--customBackgroundColor);
}

.imageContainer {
  padding: 0 !important;
}
