@import '../../styles/propertySets.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;

  @media (--viewportLarge) {
    padding: 128px 0 82px 128px;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--customPurpleLight);
    box-shadow: none;
    border-top: none;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  flex-grow: 1;
  padding: 30px 24px 0 24px;

  @media (--viewportLarge) {
    padding: 88px 64px 82px;
  }

  @media (--viewportMLarge) {
    padding: 88px 120px 82px;
    background-color: var(--matterColorLight);
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

.tabsHolder {
  position: relative;
}

.backToVenueBtn {
  position: absolute;
  top: 60px;
  left: 25px;

  @media (--viewportLarge) {
    top: 70px;
    left: 128px;
  }
}

.backButton {
  @apply --marketplaceButtonStyles;
  color: var(--matterColorLight);
  width: auto;
  padding: 0 10px;
  margin: 0;
  min-height: 0;
  display: flex;

  @media (--viewportLarge) {
    top: 5px;
    left: 10px;
    padding: 9px 10px;
  }
}
