.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.field {
  max-width: 564px;
}

.titleInputIcon {
  margin-top: 0;
  line-height: 16px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
}
.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.selectDropdownWrap {
  width: 50%;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.selectDropdown {
  composes: formMargins;
  flex-shrink: 0;
  width: 40%;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColor);
  max-width: 564px;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.groupsFourRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      padding-right: 10px;
      width: 33.3333333%;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 25%;
    }
  }
}

.groupsThreeRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 50%;
      padding-right: 10px;
    }
  }

  @media (--viewportLarge) {
    & > ul > li {
      width: 33.333333%;
    }
  }
}

.maxSeats {
  composes: formMargins;
  padding: 20px 0;
  margin-top: 28px;

  & input {
    max-width: 106px;
  }
}

.maxSafeAttendees {
  composes: formMargins;
  padding: 20px 40px 20px 20px;
  background-color: var(--customBackgroundColor);
  margin-top: 28px;

  & input {
    max-width: 106px;
  }
}

.maxSeatsWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 340px;
}

.safeMaxContainer {
  position: relative;
}

.tipContainer {
  position: absolute;
  right: 14px;
  top: 44px;

  @media (--viewportMedium) {
    top: 54px;
  }
}

.safeMaxItem {
  width: 13px;
  height: 13px;
  line-height: 13px;
  padding: 0;
  display: inline-block;
  border: 1px solid #666;
  border-radius: 50px;
  color: #666;
  text-align: center;
  flex-shrink: 0;
  font-size: 75%;

  @media (--viewportMedium) {
    display: block;
  }
}

@media (pointer: coarse), (hover: none) {
  .safeMaxItem {
    position: relative;
    display: inline-flex;
    justify-content: center;
  }
  .safeMaxItem:focus,
  .safeMaxItem:active {
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
  .safeMaxItem:focus::after {
    content: attr(title);
    position: absolute;
    top: 20px;
    right: 0;
    color: var(--matterColorLight);
    background-color: var(--matterColor);
    width: 280px;
    padding: 5px;
    z-index: 1;
    font-size: 13px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
  }
}
