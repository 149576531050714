@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  text-transform: none;
  font-size: 16px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 20px;
  padding-bottom: 30px;
  border: none;
}

.list {
  margin: 0;
}

.listTwoColumns {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listThreeColumns {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.item,
.itemWithoutMap,
.itemTwoColumns,
.itemThreeColumns,
.itemTwoColumnsWithoutMap,
.itemThreeColumnsWithoutMap {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.itemWithoutMap {
  width: 100%;
}

.itemTwoColumns {
  width: 100%;

  @media (--viewportLarge) {
    width: 50%;
  }
}

.itemThreeColumns {
  width: 100%;

  @media (--viewportLarge) {
    width: 33%;
    padding: 0 5px;
  }
}

.itemTwoColumnsWithoutMap {
  width: 100%;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.itemThreeColumnsWithoutMap {
  width: 100%;

  @media (--viewportMedium) {
    width: 33%;
  }
}

.optionCheckBox {
  font-size: 14px;
  color: var(--matterColor);
  line-height: 20px;
  padding: 5px 0;
  font-weight: var(--fontWeightMedium);
}

.legend {
  font-weight: 700;
  font-size: 16px;
}
