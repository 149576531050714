.root {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /* justify-content: flex-end; */
  padding: 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadow);

  @media (--viewportLarge) {
    padding: 128px 0 82px 128px;
    flex-direction: column;
    justify-content: flex-start;
    background-color: var(--customPurpleLight);
    border: none;
    box-shadow: none;
  }
}

.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  min-height: 48px;
  padding-top: 2px;

  @media (--viewportMedium) {
    min-height: 56px;
    padding-top: 10px;
  }

  @media (--viewportLarge) {
    min-height: auto;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin: 0;
    padding: 0;
    & a {
      margin: 0 0 52px;
      font: normal normal normal 20px/30px var(--fontFamily);
    }
  }
  & a.activeLinkClass {
    display: inline-block;
    width: auto;
    background-image: none;
    padding: 0 0 7px;
    border-bottom: 3px solid var(--matterColorDark);
    font: normal normal 600 20px/30px var(--fontFamily);
  }
}

.sidebarTitle {
  font-size: 18px;
  line-height: 1.5;
  margin: 12px 0;
  display: none;

  @media (--viewportLarge) {
    font: normal normal 600 40px/56px IBM Plex Sans;
    margin: 0 0 42px;
    display: block;
  }
}

.profilePageNav {
  @media (--viewportLarge) {
    padding: 0 0 0 24px;
    margin: 0;
  }
}
