.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
  max-width: 564px;
}
.titleInputIcon {
  margin-top: 0;
  line-height: 16px;
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  font-family: 'IBM Plex Sans', Helvetica, Arial, sans-serif;
}
.field {
  composes: formMargins;
  flex-shrink: 0;
  max-width: 564px;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.locationAutocompleteInput {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 2px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.groupsFiveRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      width: 33.3333333%;
      padding-right: 10px;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 20%;
    }
  }
}

.groupsFourRows {
  composes: formMargins;

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  @media (--viewportSmall) {
    & > ul > li {
      padding-right: 10px;
      width: 33.3333333%;
    }
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 25%;
    }
  }
}

.groupsThreeRows {
  composes: formMargins;
  padding: 0 18px;
  margin: 0 -18px;
  background-color: var(--customBackgroundColor);
  border-radius: 10px;

  & legend {
    padding-top: 30px;
  }

  & > ul {
    display: flex;
    flex-wrap: wrap;
  }

  & > ul > li {
    width: 100%;
  }

  & > ul > li > span > label > span {
    font-size: 20px;
  }

  @media (--viewportMedium) {
    & > ul > li {
      width: 50%;
      padding-right: 10px;
    }
  }

  @media (--viewportForCard) {
    & > ul > li {
      width: 33.333333%;
    }
  }
}

.icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 16px;

  @media (--viewportMedium) {
    margin-right: 20px;
  }

  @media (--viewportLarge) {
    margin-right: 30px;
  }
}

.radioLabel {
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 6px;
  padding-bottom: 2px;
  font-size: 14px;
  line-height: 16px;
}

.radioButtonRow {
  composes: formMargins;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.radioButtonRow > li {
  width: 20%;
}

.radio {
  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}
