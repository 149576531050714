.root {
}
.flagImg {
  top: 8px;
  left: 15px;
  position: absolute;
  width: 22px;
  height: 15px;
}
.inputBlock {
  position: relative;
}
.input {
  border-bottom-color: var(--marketplaceColor);
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.textareaIcon {
  text-indent: 45px;
}
