@import '../../styles/propertySets.css';

.root {
  background-color: var(--customBlue);
}

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColorLight);
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 25px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    font: normal normal 600 40px/58px var(--fontFamily);
  }
}

.titleWrapper {
  display: flex;
  margin-bottom: 60px;
  flex-direction: column;
  align-items: flex-start;

  @media (--viewportMedium) {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.goAll {
  font: normal normal 600 16px/21px var(--fontFamily);
  color: var(--customBlue);
  background-color: var(--customLightBlue);
  padding: 10px 30px;
  border-radius: var(--borderRadiusButton);
}

.subtitle {
  font-size: 16px;
  color: var(--matterColorLight);
  margin-bottom: 32px;
  margin-top: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
    font: normal normal 300 18px/24px var(--fontFamily);
  }
}

.activities {
  margin: 0 -20px;
}

.activitiesWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  height: auto;

  @media (--viewportLarge) {
    flex-wrap: wrap;
    height: 550px;
  }
}

.activity {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 300px;
  width: calc(100% - 48px);
  margin: 0 24px 24px;
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }

  @media (--viewportLarge) {
    height: 25%;
    width: calc(25% - 40px);
    margin: 20px;
    flex: 1 1 230px;
  }
}

.activity:nth-child(1) {
  flex: 1 0 100%;
  padding: 0;
  margin: 0 24px 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  height: 300px;
  width: calc(100% - 48px);

  & .imageWrapper {
    flex: 1 0 0;
  }

  & .activityText {
    padding: 14px 28px;
    font: normal normal 600 20px/31px var(--fontFamily);
    margin-bottom: 0;
  }
  & .activitySubText {
    font-size: 18px;
    font-weight: 400;
    padding-right: 28px;
  }

  @media (--viewportLarge) {
    width: calc(50% - 40px);
    max-height: calc(100% - 40px);
    margin: 20px;
  }
}

.activity:nth-child(1)::before,
.activity:nth-child(1)::after {
  content: '';
  display: block;
  right: 37px;
  position: absolute;
  bottom: 53px;
  transition: left 0.1s;
  z-index: 2;
}
.activity:nth-child(1)::before {
  border-color: var(--marketplaceColor);
  border-style: solid;
  border-width: 0 0 3px 3px;
  height: 15px;
  width: 15px;

  transform: rotate(225deg);
}
.activity:nth-child(1)::after {
  background: var(--marketplaceColor);
  height: 3px;
  width: 22px;
  bottom: 59px;
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  height: 100%;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding: 0;
  height: 100%;
}

.activityImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.activityText {
  @apply --marketplaceH2FontStyles;
  letter-spacing: -0.23px;
  text-shadow: var(--boxShadowPopupLight);
  margin: 0;
  background-color: var(--matterColorLight);
  padding: 12px 0 12px 12px;
  font: normal normal 600 16px/22px var(--fontFamily);
  letter-spacing: -0.09px;
  color: var(--matterColor);
}

.activityLink {
  color: var(--matterColor);
  transition: all 0.3s ease;
}
