.amenitiesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportMedium) {
    margin: 0 -14px;
  }
}

.amenitiesCheckboxWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(50% - 20px);
  padding: 5px 5px;
  background-color: var(--customBackgroundColor);
  min-width: 350px;

  @media (--viewportMedium) {
    /* width: 50%;
    display: flex;
    justify-content: unset; */
    padding: 5px 19px;
  }
}

.amenitiesCheckboxWrapFullWidth {
  display: block;
  flex-direction: row;
  width: 100%;
  @media (--viewportMedium) {
    width: 100%;
    display: flex;
  }
}

.freeCheckbox {
  margin-left: 15px;

  & input[disabled] + label {
    opacity: 0.5;
    pointer-events: none;
  }
}

.optionsWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.optionsPart {
  display: flex;
  flex-wrap: wrap;
}
