@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;
  margin-top: 30px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
    margin-top: 24px;
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;

  &:nth-child(2n) {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.fieldDisabled {
  background: none;
  color: --matterColorNegative;
}

.fieldDateInput {
  flex-grow: 1;
}

.fieldDateInput > label {
  @apply --marketplaceCustomLabel;
}

.fieldSelect {
  flex-grow: 1;
  margin-left: 24px;
  margin-right: 24px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 24px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      left: 0;
      bottom: 12px;
    }
  }
}

.fieldSelect > select {
  color: var(--matterColor) !important;
}

.fieldSelect > label {
  @apply --marketplaceCustomLabel;
}

.fieldSelectDuration {
  flex-grow: 1;
  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.fieldSelectDuration > label {
  @apply --marketplaceCustomLabel;
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 24px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }
  @media (--viewportMedium) {
    &::after {
      left: 0;
      bottom: 12px;
    }
  }
}

.fieldSelectDurationDisabled {
  composes: fieldSelectDuration;

  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.fieldSelectDurationDisabled > label {
  @apply --marketplaceCustomLabel;
}

.select,
.selectDuration {
  position: relative;
  padding-left: 24px;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;
  border-bottom-width: 2px;

  @media (--viewportMedium) {
    border-bottom-width: 3px;
  }
}

.selectSeats {
  position: relative;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;
  border-bottom-width: 2px;

  @media (--viewportMedium) {
    border-bottom-width: 3px;
  }
}

.fieldSelectSeats {
  flex-grow: 1;
  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.fieldSelectSeats > label {
  @apply --marketplaceCustomLabel;
}

.selectDuration {
  padding: 0;
}

.selectDisabled,
.selectDurationDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

.selectDurationDisabled {
  padding: 0;
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code.
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

.field {
  width: calc(50% - 5px);
  @media (--viewportMedium) {
    width: calc(50% - 12px);
  }
}

.startDate {
  width: 100%;
}
.endDateHidden {
  display: none;
}

.lineBetween,
.lineBetweenDisabled {
  margin-top: 36px;
}
.lineBetweenDisabled {
  color: var(--matterColorNegative);
}

.locationAutocompleteInput,
.locationAutocompleteInputDisabled {
  flex-grow: 1;
  height: 36px;
  line-height: unset;
  padding-left: 0;
  margin: 0;
  border-bottom: 3px solid var(--marketplaceColor);
  max-width: 564px;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColorDark);
    outline: none;
  }

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  @media (--viewportMedium) {
    height: 40px;
  }
}

.locationAutocompleteInputDisabled {
  border-bottom: 3px solid var(--matterColorNegative);
}

.locationAutocompleteInputIcon {
  display: none;
}

.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 36px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    top: 40px;
  }
}

.validLocation {
  border-bottom-color: var(--successColor);
}

.autocompleteRoot {
  width: 100%;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.safeSpacingSwitchHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 24px 20px;

  @media (--viewportMedium) {
    padding: 25px 0 20px;
  }
}

.meetSafeContainer {
  margin: 0;
  padding: 0 10px 0 16px;
  color: var(--customGray);
}
