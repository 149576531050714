@import '../../styles/propertySets.css';

.topbar {
  z-index: 1; /* ensure that Topbar shadow overlays content background */
}

.mobileTopbar {
  position: relative;
  width: 100%;
  height: var(--topbarHeight);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent linear-gradient(4deg, #044462, #010e14) 0 0 no-repeat padding-box;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.navigation {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 29px 24px 0 24px;

  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  border-top-style: solid;
  background-color: var(--matterColorBright);
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    padding: 70px 20px 82px 36px;
    flex-direction: column;
    justify-content: flex-start;
    border-top: none;
    box-shadow: none;
    border-right: 1px solid var(--customBorderGray);
  }
  @media (--viewportForCard) {
    padding: 70px 20px 82px 132px;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 0;

  padding-bottom: 10px;

  @media (--viewportMedium) {
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.title span {
  font-family: var(--fontFamilyPoppins) !important;
}

.tabs {
  padding-top: 2px;

  display: flex;
  flex-direction: row;
  overflow-x: auto;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-top: 20px;
  }

  @media (--viewportLarge) {
    padding-top: 53px;
    flex-direction: column;
  }
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 0;
  }
}

.tabTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px 0 0px;
}

.tabTitle span {
  font-family: var(--fontFamilyPoppins) !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: var(--fontWeightRegular);

  @media (--viewportLarge) {
    line-height: 40px;
    font-size: 20px;
  }
}

.error {
  color: var(--failColor);
  margin: 23px 24px 24px 24px;

  @media (--viewportLarge) {
    margin-top: 18px;
  }
}

.noResultsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noResults {
  font-size: 18px;
  padding: 0 24px 16px;

  @media (--viewportLarge) {
    padding: 6px 24px 16px;
  }
}

.noResultsText {
  font-size: 18px;
  margin: 0;
}

.itemList {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--customBorderGray);
}

@keyframes loadingSpinnerFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.listItemsLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%;
  opacity: 0;

  /* animation shorthand property doesn't work with local scope of CSS Modules */
  animation-duration: 1s;
  animation-name: loadingSpinnerFadeIn;
  animation-fill-mode: forwards;
}

.listItem {
  /* Layout */
  margin-bottom: 10px;
  padding-bottom: 12px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 16px;
    padding-bottom: 12px;
  }
}

.item {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.itemLink {
  flex: 1;

  /* Layout */
  display: flex;

  /* Remove text underline from this link */
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }

  &:hover .itemUsername {
    /* Hightlight the username when the whole item is hovered */
    color: var(--marketplaceColor);
  }
}

.itemAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 15px;
  }
}

.rowNotificationDot {
  width: 6px;
  height: 6px;
  margin-top: 10px;
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-right: 13px;
    width: 9px;
    height: 9px;
  }
}

.notificationDot {
  color: var(--matterColorLight);

  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--failColor);
}

.itemInfo {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Layout */
  flex-grow: 1;
  flex: 1;
  flex-direction: column;

  margin-top: 5px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  color: var(--matterColor);
}

.itemHeading {
  display: flex;
  align-items: center;
}

.lastMessageText {
  font-size: 13px;
  color: var(--matterColor);
  max-width: 315px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--fontWeightRegular);
}

.itemUsername {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  line-height: 18px;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 0;
  margin-right: 25px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 75px;
  }
}

.bookingInfoWrapper {
  @apply --marketplaceTinyFontStyles;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  line-height: 14px;
  margin-top: 2px;
  padding-top: 2px;
  color: var(--matterColor);

  @media (--viewportMedium) {
    padding-top: 0px;
    margin-top: 2px;
    line-height: 16px;
  }
}

.itemPrice {
  &::before {
    font-size: 10px;
    margin: 0 6px;
    display: inline-block;
    content: '\25CF'; /* middot */
  }
}

.itemState {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);
  margin-top: 6px;
  margin-bottom: 0px;
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.lastTransitionedAt {
  @apply --marketplaceTinyFontStyles;

  margin-top: 4px;
  text-align: right;
  color: var(--matterColor);
  margin-bottom: 70px;
}

.pagination {
  margin: auto 24px 0 24px;
}

/* Transaction status affects to certain font colors and weights */

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateActionNeeded {
  font-weight: var(--fontWeightSemiBold);
  color: var(--attentionColor);
}

.stateNoActionNeeded {
  color: var(--matterColorAnti);
}

.stateDeclined {
  color: var(--customRed);
  display: flex;
  flex-direction: row;
}

.stateSucces {
  font-weight: var(--fontWeightSemiBold);
  color: var(--customGreen);
  display: flex;
  flex-direction: row;
}

.nameEmphasized {
  font-weight: var(--fontWeightBold);

  /* baseline alignment fixes */
  margin-top: -1px;
  margin-bottom: 1px;
}

.nameNotEmphasized {
  font-weight: var(--fontWeightMedium);
}

.bookingActionNeeded {
  color: var(--matterColor);
  margin-top: 4px;

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.bookingNoActionNeeded {
  color: var(--matterColor);
  margin-bottom: 8px;
}

.lastTransitionedAtEmphasized {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
}

.lastTransitionedAtNotEmphasized {
  color: var(--matterColor);
}

.bannedUserLink {
  & .itemUsername,
  &:hover .itemUsername,
  & .bookingInfo,
  & .stateName,
  & .lastTransitionedAt {
    color: var(--matterColorAnti);
  }
}

.navPlaceholder {
  @media (--viewportMedium) {
    width: 240px;
  }
}

.notoficationBadge,
.notoficationBadgeAccept,
.notoficationBadgeRequest {
  line-height: 22px !important;
  font-size: 16px !important;
}

.notoficationBadgeAccept {
  background-color: var(--customGreen);
}

.notoficationBadgeRequest {
  background-color: var(--customOranngeTwo);
}

.transactionInfoContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportSmall) {
    flex-direction: row;
  }
}

.userNameContainer {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  line-height: 20px;
  font-weight: var(--fontWeightRegular);
  margin: 0;
  padding: 0 0 24px;
}

.userName {
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 0 0 0 5px;
}

.lisitngImageContainer {
  /* Layout */
  display: block;
  width: 100%;
  max-height: 87px;
  max-width: 143px;
  position: relative;
  overflow-y: hidden;
  margin: 0 37px 0 0;

  @media (--viewportLarge) {
  }
}

.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.infoTransactionText {
  font-family: var(--fontFamilyPoppins);
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  margin: 0;
  padding: 0 10px 6px 0;
}

.moreContainer {
  display: flex;
  flex-direction: row;
}

.infoTransactionMoreText {
  font-family: var(--fontFamilyPoppins);
  font-size: 13px;
  line-height: 18px;
  font-weight: var(--fontWeightRegular);
  margin: 0;
  padding: 0 12px 0 0;
}

.bookingDate {
  margin-bottom: 0;
  margin-top: 0;
  padding: 0 0 0 0;
}

.arrow {
  border-bottom: 1px solid var(--matterColor);
  border-right: 1px solid var(--matterColor);
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  margin: 3px 0 0;
}

.bookingInfoContainer {
  padding: 10px 0 0;

  @media (--viewportSmall) {
    padding: 0;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.seeTransactionButton,
.seeTransactionButtonMobile {
  font-size: 16px;
  line-height: 32px;
  margin: 0;
  border-radius: unset;
  padding: 0 12px;
  text-transform: capitalize;
}

.seeTransactionButton {
  display: none;

  @media (--viewportSmall) {
    display: block;
  }
}

.seeTransactionButtonMobile {
  @media (--viewportSmall) {
    display: none;
  }
}

.stateInfoTextDeclined {
  color: var(--customRed);
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  padding: 25px 10px 10px 0;
  font-weight: var(--fontWeightRegular);
}

.stateInfoTextAccepted {
  color: var(--customGreenTwo);
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  padding: 25px 10px 10px 0;
  font-weight: var(--fontWeightRegular);
}

.notificationCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  color: var(--matterColorLight);
  margin: 0 10px 0 0;
}

.notificationDeclined {
  background-color: var(--customRed);
}

.notificationAccepted {
  background-color: var(--customGreen);
}

.textFilter {
  text-transform: none;
  font-size: 15px;
}
.wrapperLazyLoad {
  width: 100%;
  margin: 15px 0;
}
.showMore {
  @apply --marketplaceButtonStyles;
  display: flex;
  text-align: center;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  height: 20px;
  width: 200px;
  padding: 0;
  font-size: 15px;
  background: white;
  color: var(--matterColor);
  border: 1px solid #e6e6e6;
}

.searchBarContainer {
  display: flex;
  align-items: center;
  padding: 20px 0 61px;

  @media (--viewportLarge) {
    padding: 0 0 61px;
  }
  @media (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 20px;
  }
}

.inboxSearchBarRoot {
  width: 100%;

  @media (max-width: 430px) {
    padding: 10px 0 0;
  }
}

.contentWrapperInput {
  width: 170px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.inputKeywords {
  width: 100%;

  & input {
    border-bottom-width: 0;
    padding: 9px 0 9px 19px;
  }
}

.typeFilterCount {
  width: 130px;
}
.typeFilterRevers {
  @apply --marketplaceButtonStyles;
  max-width: 140px;
  height: 44px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: white;
  color: var(--matterColor);
}
.typeFilterRevers:hover {
  color: white;
}

.pulse {
  position: fixed;
  bottom: 15%;
  right: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  border-radius: 50%;
  background: #48c39c;
  background: ;
  cursor: pointer;
  box-shadow: 0 0 0 #2c8870;
  animation: pulse 2s infinite;
}
.pulse:hover {
  animation: none;
  color: white;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 136, 112, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(44, 136, 112, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(44, 136, 112, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 136, 112, 0.4);
    box-shadow: 0 0 0 0 rgba(44, 136, 112, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(44, 136, 112, 0);
    box-shadow: 0 0 0 15px rgba(44, 136, 112, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(44, 136, 112, 0);
    box-shadow: 0 0 0 0 rgba(44, 136, 112, 0);
  }
}
.icon {
  position: relative;
  bottom: 1px;
  margin-right: 5px;

  @media (--viewportMedium) {
    bottom: 2px;
  }

  & g {
    stroke: var(--matterColor);
  }
}

.typeFilterRevers:hover .icon,
.typeFilterRevers:focus .icon {
  & g {
    stroke: var(--matterColorLight);
  }
}

.wrapperSwitchRole {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 23px;
  background: #efefef 0% 0% no-repeat padding-box;
  padding: 6px;
  width: 250px;
  height: 30px;
  font-size: 15px;
  left: 0;
  opacity: 0.7;
  transition: all 0.2s;
  @media (--viewportMedium) {
    margin-top: 30px;
    padding: 8px;
    width: 300px;
    height: 46px;
    font-size: 20px;
  }
  @media (--viewportForCard) {
    left: -50px;
    width: 300px;
  }
}

.sectionSwitchRole {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 122px;
  height: 24px;
  color: #4a4a4a;
  border-radius: 23px;
  &:hover {
    color: #4a4a4a;
    text-decoration: none;
  }
  @media (--viewportMedium) {
    height: 36px;
    width: 153px;
  }
}
.activeRole {
  font-weight: bold;
  background: white;
  opacity: 1;
}
