@import '../../styles/propertySets.css';
@import '../../styles/marketplaceDefaults.css';

.root {
  margin-top: 60px;

  @media (--viewportMLarge) {
    padding-top: 120px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  margin-right: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    justify-content: space-between;
    margin-right: 40px;
    &:last-child {
      margin-bottom: 24px;
    }
  }

  @media (--viewportMLarge) {
    flex-direction: row;
    align-items: stretch;
    margin-bottom: 0;
    margin-right: 100px;
    &:last-child {
      margin-bottom: 24px;
    }
  }
}

.stepTitle {
  @apply --marketplaceCustomH2FontStyles;
  margin-top: 18px;
  margin-bottom: 18px;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 15px;
    font: normal normal bold 30px/38px var(--fontFamily);
    color: var(--customBlue);
    text-transform: uppercase;
  }

  @media (--viewportMLarge) {
    text-align: left;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.stepText {
  flex: 1 1;
  & p {
    color: var(--customBlue);
    font: normal normal normal 16px/22px var(--fontFamily);
  }
}

.stepImage {
  border-radius: 50%;
  background: var(--customLightGray);
  height: 105px;
  width: 105px;
  padding: 10px;
  box-shadow: var(--boxShadowPopupLight);
  margin-bottom: 16px;
  flex-basis: 105px;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @media (--viewportMLarge) {
    margin-bottom: 0;
    margin-right: 36px;
  }
}
