@import '../../styles/propertySets.css';

.root {
  background-color: var(--matterColorLight);
}

.mainContent {
  display: flex;
  align-items: center;
}

.content {
  width: calc(100% - 48px);
  /* margin: 0 24px 24px 24px; */

  @media (--viewportMedium) {
    /* max-width: 565px; */
    /* margin: 0 auto 56px auto; */
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.topbar {
  z-index: 1;
}

.mobileTopbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent linear-gradient(4deg, #044462, #010e14) 0 0 no-repeat padding-box;

  /* shadows */
  box-shadow: none;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;

  @media (--viewportLarge) {
    box-shadow: var(--boxShadowLight);
  }
}

.wizard,
.placeholderWhileLoading {
  flex-grow: 1;
}

.emptyTitle {
  margin-bottom: 40px;
}

.emptyButton {
  @apply --marketplaceButtonStyles;
  width: auto;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}

.listingPanel {
  width: 100%;
  margin: 24px auto 0 auto;

  @media (--viewportMedium) {
    margin: 0 auto;
  }
}

.title {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 24px 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 36px 48px 36px;
  }
}

.listingCards {
  /* padding: 0 24px 96px 24px; */
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 0 0 30px;
  align-items: center;
  justify-content: space-around;

  @media (min-width: 660px) {
    flex-direction: row;
    flex-basis: 50%;
    flex-wrap: wrap;
  }

  @media (--viewportMLarge) {
    flex-direction: column;
    flex-basis: 100%;
  }

  /* @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  } */

  /* @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  } */
}

.listingCard {
  /* margin-bottom: 36px;
  flex-basis: 100%; */

  flex-grow: 1;
  width: 100%;
  max-width: 342px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 5px 40px;

  @media (--viewportMLarge) {
    flex-direction: row;
    width: 100%;
    max-width: unset;
  }

  @media (--viewportMedium) {
    /**
     * resultPanel: 62.5vw from 1024px = 640px
     * panelPaddings: - 2*36px = 72px
     *
     * columnCount: 2
     * gutterWidthBetweenColumns: 24px
     *
     * (resultPanel - (panelPaddings + gutterWidthBetweenColumns)) / columnCount = listingCardWidth
     * ergo => listingCardWidth: 272px
     *
     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */
    /* flex-basis: calc(50% - 12px);
    margin-right: 24px; */
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%
     *
     * resultPanel: 62.5vw from 1920px = 1200px
     * panelPaddings: - 2*36px = 72px
     *
     * columnCount: 3
     * guttersBetweenColumns: 2*24px = 48px
     *
     * (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
     * ergo => listingCardWidth: 360px
     *
     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */
    /* flex-basis: calc(33.33% - 16px);
    margin-right: 24px; */
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

/* .listingCard:nth-of-type(2n) {
  @media screen and (min-width: 768px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
} */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  padding-top: 36px;
  text-align: center;
}

.modalButtonsWrapper {
  padding-top: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.cancelListingDelete {
  color: var(--matterColorAnti);
  cursor: pointer;
  width: 50%;
  text-align: right;
  padding-right: 64px;

  &:hover {
    color: var(--matterColor);
  }
}
