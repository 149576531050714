@import '../../styles/propertySets.css';

.root {
}

.tableWrapper {
  margin: 0 -10px;

  @media (--viewportMLarge) {
    margin: 0 -15px;
  }

  @media (--viewportLLarge) {
    margin: 0 -30px;
  }
}

.input {
  padding: 5px 0 5px 0;
  border: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--attentionColor);
  border-radius: 0;

  @media (--viewportMedium) {
    padding: 4px 0 2px 0;
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.inputsWrapper {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -10px;
}

.inputItem {
  width: 100%;
  margin-bottom: 20px;
  padding: 0;

  @media (--viewportMedium) {
    margin-bottom: 0;
    width: 33.3333333%;
    padding: 0 10px;
  }
}

.table {
  width: 100%;
  position: relative;
  & > * {
    width: 100%;
  }
}

.tableRow,
.tableRowHead {
  background-color: var(--customBackgroundColor);
  border-bottom: 10px solid white;

  & > td {
    width: 50%;
    padding: 10px;
  }

  & > th {
    text-align: left;
    font-size: 18px;
    line-height: 20px;
    padding: 0 10px;
  }

  @media (--viewportMedium) {
    & > td:nth-child(2),
    & > th:nth-child(2) {
      width: 36%;
    }

    & > td {
      vertical-align: bottom;
      width: auto;
    }
  }

  @media (--viewportMLarge) {
    & > td {
      padding: 15px;
    }

    & > th {
      padding: 0 15px;
    }
  }

  @media (--viewportLLarge) {
    & > td {
      padding: 30px;
    }

    & > th {
      padding: 0 30px;
    }
  }
}

.tableRowHead {
  background-color: transparent;
}

.checkbox {
  white-space: nowrap;
}

.lastRow > td {
  padding-bottom: 40px;
}

.label {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}

.absoluteHead {
  position: absolute;
  left: 15px;
  top: 0px;
}

.currencyInput {
  position: relative;
}

.currencyInput > div {
  position: absolute;
}

.packageTitle {
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  margin: 20px 0;
}

.packageDetails {
  padding: 10px 20px;
  font-size: 0.7em;
  opacity: 0.5;
  font-style: italic;
}

/* .infoIcon {
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  width: 20px;
  height: 20px;
}

.packageDetailsInfo {
  padding: 0 32px;
} */
