.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 0;
}

.error {
  color: var(--failColor);
}

.otherPackageDescription {
  margin: 20px 0 0;

  & label {
    margin-bottom: 20px;
  }
}

.submitButton {
  margin-top: 100px;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin: 100px 0 0;
  }
}

.amenitiesCheckboxWrap {
  display: block;
  flex-direction: row;
  width: 100%;
  @media (--viewportMedium) {
    width: 50%;
    display: flex;
  }
}

.freeCheckbox {
  margin-left: 15px;
}

.optionsWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.optionsPart {
  display: flex;
  flex-wrap: wrap;
}
