@import '../../styles/propertySets.css';

.root {
  margin: 0;

  /* Clearfix */
  @apply --clearfix;
}

.messageItem {
  margin-bottom: 20px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 17px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.message,
.ownMessage {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  max-width: 433px;
}

.message {
  display: flex;
  flex-direction: row;
}

.ownMessage {
  float: right;
}

.avatar {
  flex-shrink: 0;
  margin: 0px 15px 0 0;
}

.ownMessageContentWrapper {
  @apply --clearfix;
}

.messageContent,
.ownMessageContent {
  @apply --marketplaceMessageFontStyles;

  display: inline-block;
  margin: 0;
  padding: 8.5px 14px 8.5px 14px;
  border-radius: 8px;
  box-shadow: var(--boxShadow);

  @media (--viewportMedium) {
    padding: 8.5px 14px 8.5px 14px;
    margin: 0;
  }
}

.messageContent {
  flex: 1;
}

.ownMessageContent {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  float: right;
}

.messageDate,
.ownMessageDate {
  @apply --marketplaceMessageDateFontStyles;

  margin: 11px 0 0 0;
  color: var(--matterColorAnti);

  @media (--viewportMedium) {
    margin: 6px 0 0 0;
  }
}

.ownMessageDate {
  text-align: right;
}

.transitionItem {
  margin-bottom: 18px;

  /* Clearfix */
  @apply --clearfix;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.transition {
  display: flex;
  flex-direction: row;
  padding: 3px 0 2px 0;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.transitionContent {
  @apply --marketplaceTxTransitionFontStyles;
  margin: 0;
}

.transitionDate {
  @apply --marketplaceMessageDateFontStyles;
  color: var(--matterColorAnti);
  margin: 7px 0 0 0;

  @media (--viewportMedium) {
    margin: -1px 0 1px 0;
  }
}

.bullet {
  margin-right: 6px;
}

.reviewContent {
  @apply --marketplaceH4FontStyles;
  font-style: italic;
  white-space: pre-line;
  margin: 8px 0 0 0;

  @media (--viewportMedium) {
    max-width: 500px;
    margin: 7px 0 0 0;
  }
}

.reviewStars {
  display: block;
  margin-top: 4px;

  @media (--viewportMedium) {
    margin: 5px 0;
  }
}

.reviewStar {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  font-styles: initial;
}

.showOlderWrapper {
  text-align: center;
  margin-bottom: 1px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.showOlderButton {
}

.newMessagesStyle {
  & .message,
  & .ownMessage {
    display: flex;
    flex-direction: column;
    float: none;
    max-width: 100%;
    text-align: left;
    border-bottom: 1px solid var(--customBorderGray);
    padding-bottom: 30px;
  }

  & .messageAvatarHolder {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  & .avatar {
    background-image: linear-gradient(
      -180deg,
      var(--customBlueBg) 0%,
      var(--customDarkBlueBg) 100%
    );
  }

  & .messageAvatarHolder b {
    margin-left: 4px;
  }

  & .ownMessageContent {
    float: none;
  }

  & .messageContentWrapper,
  & .ownMessageContentWrapper {
    padding-left: 40px;
  }

  & .messageContent,
  & .ownMessageContent {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    color: var(--matterColor);
    padding: 0 5px;
  }
}
