@import '../../styles/propertySets.css';

.root {
  padding: 64px 24px;
  margin: 0 auto;

  @media (--viewportLarge) {
    padding: 100px 0;
  }
}

.title {
  @apply --marketplaceH3FontStyles;

  margin-top: 0;
  margin-bottom: 8px;
  & span {
    font: normal normal medium 18px/24px var(--fontFamily);
    color: var(--matterColor);
  }
}

.subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: var(--marketplaceColor);
}

.commentContainer {
  display: flex;
  justify-content: space-between;
  background-color: var(--matterColorLight);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  flex-direction: column;
  padding: 20px 44px 24px;

  @media (--viewportLarge) {
    max-width: 740px;
    margin-left: 300px;
    padding: 24px 48px;
    flex-direction: row;
  }
}

.main {
  max-width: 426px;
  margin-right: 0;
  order: 2;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 1;
    margin-right: 72px;
    padding-left: 30px;
  }
}

.header {
  margin-bottom: 32px;
  order: 2;

  @media (--viewportLarge) {
    order: 1;
  }
}

.commentText {
  font: normal normal medium 18px/24px var(--fontFamily);
  color: var(--matterColor);
  margin-bottom: 32px;
  position: relative;
  order: 3;

  @media (--viewportLarge) {
    order: 2;
  }
}

.commentText::before,
.commentText::after {
  content: '“';
  position: absolute;
  color: var(--matterColor);
  font: normal normal 600 53px/53px var(--fontFamily);
}

.commentText::before {
  top: -24px;
  left: -25px;
}

.commentText::after {
  bottom: -24px;
  right: -25px;
  transform: rotate(180deg);
}

.stars {
  display: flex;
  justify-content: center;
  order: 1;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    order: 3;
    margin-bottom: 0;
  }
}

.star {
  margin: 0 7px;
}

.author {
  padding-bottom: 24px;
  order: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportLarge) {
    order: 2;
    display: block;
    padding-top: 10px;
  }
}

.authorAvatar {
  border-radius: 50%;
  width: 131px;
  height: 131px;
  margin-bottom: 24px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    display: block;
  }
}

.authorTitle {
  font: normal normal 600 18px/24px var(--fontFamily);
  color: var(--matterColor);
}

.authorSubtitle {
  font: normal normal 300 18px/24px var(--fontFamily);
  color: var(--matterColor);
}
