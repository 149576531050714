@keyframes :global(btnGradientAnimation) {
  0% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 100%, rgba(4, 68, 98, 1) 100%);
  }
  10% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 90%, rgba(4, 68, 98, 1) 100%);
  }
  20% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 80%, rgba(4, 68, 98, 1) 100%);
  }
  30% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 70%, rgba(4, 68, 98, 1) 100%);
  }
  40% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 60%, rgba(4, 68, 98, 1) 100%);
  }
  50% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 50%, rgba(4, 68, 98, 1) 100%);
  }
  60% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 40%, rgba(4, 68, 98, 1) 100%);
  }
  70% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 30%, rgba(4, 68, 98, 1) 100%);
  }
  80% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 20%, rgba(4, 68, 98, 1) 100%);
  }
  85% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 15%, rgba(4, 68, 98, 1) 100%);
  }
  90% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 10%, rgba(4, 68, 98, 1) 100%);
  }
  95% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 5%, rgba(4, 68, 98, 1) 100%);
  }
  100% {
    background: rgb(72, 195, 156);
    background: linear-gradient(90deg, rgba(72, 195, 156, 1) 0%, rgba(4, 68, 98, 1) 0%);
  }
}

@keyframes :global(btnGradientAnimationFail) {
  0% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 100%, #94120b 100%);
  }
  10% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 90%, #94120b 100%);
  }
  20% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 80%, #94120b 100%);
  }
  30% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 70%, #94120b 100%);
  }
  40% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 60%, #94120b 100%);
  }
  50% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 50%, #94120b 100%);
  }
  60% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 40%, #94120b 100%);
  }
  70% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 30%, #94120b 100%);
  }
  80% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 20%, #94120b 100%);
  }
  85% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 15%, #94120b 100%);
  }
  90% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 10%, #94120b 100%);
  }
  95% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 5%, #94120b 100%);
  }
  100% {
    background: #e02020;
    background: linear-gradient(90deg, #e02020 0%, #94120b 0%);
  }
}
