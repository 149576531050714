.root {
}

.select {
  /* color: var(--matterColorAnti); */
  border-bottom-color: var(--marketplaceColor);
  padding-right: 20px;

  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: var(--matterColorNegative);
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}

.periods {
  display: flex;
}

.periodCol {
  width: 50%;

  &:first-child {
    padding-right: 10px;
  }

  &:last-child {
    padding-left: 10px;
  }

  @media (--viewportMLarge) {
    &:first-child {
      padding-right: 15px;
    }

    &:last-child {
      padding-left: 15px;
    }
  }

  @media (--viewportLLarge) {
    &:first-child {
      padding-right: 20px;
    }

    &:last-child {
      padding-left: 20px;
    }
  }
}

.period {
  @media (--viewportMLarge) {
    display: flex;
  }
}

.col {
  width: 100%;

  &:first-child {
    margin-bottom: 10px;
  }

  @media (--viewportMedium) {
    width: 100%;

    &:first-child {
      padding-right: 8px;
      margin-bottom: 0;
    }

    &:last-child {
      padding-left: 8px;
    }
  }
}

.select {
  width: 100%;
}
