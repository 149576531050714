@import '../../styles/propertySets.css';

.list {
  list-style: none;
  margin-bottom: 0;
}

.item {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item:last-child {
  padding-bottom: 0;
}

.itemContent {
  flex: 1 1;
}

.itemControl {
  display: flex;
  padding-left: 16px;
  align-items: flex-start;
}

.itemControlButton {
  display: flex;
  align-items: center;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }
}

.edit {
}

.delete {
  fill: var(--failColor);
}
