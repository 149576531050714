@import '../../styles/propertySets.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
  width: 100%;
  margin: 0 auto;
  padding: 24px 24px 0;

  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 42px 24px 0;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1268px;
    padding: 42px 36px 0;
    margin: 0 auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 42px 0 0;
  }
}

.otherImageContainer {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 26px;
  height: calc(0.3 * 100vw);
  max-height: 360px;
}

.twoImageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 15px 0 0;
  justify-content: space-between;
  height: 46%;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  /* background-color: var(--matterColorNegative); Loading BG color */
  display: flex;
  flex-direction: row;

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.sectionWithVideos {
  width: 100%;

  & .actionBarHoler {
    position: static;
    width: 100%;
    margin-bottom: 26px;
    flex-wrap: wrap;

    @media (--viewportMedium) {
      position: absolute;
      width: calc(100% - 26px);
    }
  }
}

.videoHeading {
  width: 100%;

  @media (--viewportSmall) {
    display: flex;
    justify-content: space-between;
  }

  & .videoFrame {
    margin-bottom: 12px;

    @media (--viewportSmall) {
      margin-bottom: 0;
    }
  }

  & .videoFrame,
  & .threeToTwoWrapper {
    @media (--viewportSmall) {
      width: calc(50% - 13px);
    }
  }

  & .threeToTwoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (--viewportSmall) {
      display: block;
    }
  }

  & .aspectWrapper {
    padding-bottom: 0;
    width: 100%;
  }

  & .singleRootForImage {
    position: static;

    &:hover {
      transform: scale(1);
    }
  }

  & .viewPhotos {
    right: 10px;
    bottom: 10px;

    @media (--viewportSmall) {
      bottom: 19px;
      right: 24px;
    }
  }
}

.videoHeadingWithTwoImages {
  & .threeToTwoWrapper .aspectWrapper {
    width: calc(50% - 6px);

    @media (--viewportSmall) {
      width: 100%;
    }
  }

  & .threeToTwoWrapper .aspectWrapper:first-child {
    @media (--viewportSmall) {
      margin-bottom: 26px;
    }
  }

  & .singleRootForImage {
    max-height: 180px;
  }
}

.videoFrame {
  & iframe {
    width: 100%;
    height: 100%;
    min-height: 240px;
  }
}

.actionBarHoler {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--matterColorNegative);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage,
.singleRootForImage,
.rootForSecondImage,
.secondImage,
.rootForThirdImage,
.thirdImage,
.rootForFourthImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.3 * 100vw);
    max-height: 360px;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage,
.singleRootForImage {
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 468px);
    flex-basis: calc(100% - 468px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.singleRootForImage {
  @media (--viewportLarge) {
    max-width: 100%;
  }
}

.rootForSecondImage {
  height: 50%;
}

.secondImage,
.thirdImage {
  height: 100%;
}

.rootForThirdImage,
.rootForFourthImage {
  height: auto;
  width: 48%;
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1268px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;
  margin-top: 41px;

  @media (--viewportMedium) {
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 468px);
    flex-basis: calc(100% - 468px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 41px;
    margin-left: 60px;
    /*border-left-style: solid;*/
    /*border-left-width: 1px;*/
    /*border-left-color: var(--matterColorNegative);*/

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}

.sectionHeading {
  margin: 0;
  padding: 0 0 34px;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
    padding: 0 0 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 24px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 22px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 36px;
  }
}

.descriptionTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 728px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.map > div {
  max-width: 728px !important;
}

.map > div > img {
  width: 100%;
  height: 100%;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.spacesSubHeading {
  color: var(--marketplaceColor);
}

.reviewStar {
  width: 12px;
  height: 12px;
}

.reviewsSubHeading {
  display: flex;
  flex-direction: column;
  min-width: 100px;

  & span {
    font-size: 14px;
    text-decoration: underline;
  }

  & .reviewTotalInfo {
    margin: 0;
    text-decoration: underline;
  }
}

.descriptionHandlers {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 40px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);
}

.descriptionHandlersItem {
  display: flex;
  align-items: center;
  vertical-align: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--customGray);
}

.extrasName {
  font-size: 18px;
  font-weight: 500;
}
.formattedPrice {
  font-size: 18px;
  font-weight: 500;
}

.extrasDescription {
  font-size: 14px;
}

.showMapIcon {
  width: 13px;
  height: 17px;
  margin-right: 10px;
}

.readMoreIcon {
  width: 13px;
  height: 17px;
  margin-left: 27px;
}

.descriptionItems {
  margin-top: 50px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;

  @media (--viewportMedium) {
    padding: 0 20px;
  }
}

.descriptionColumnContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.descriptionLabel {
  font-weight: 600;
}

.descriptionItem {
  margin-bottom: 27px;
  flex-basis: 50%;
}

.subTitle {
  font-size: 20px;
  margin-bottom: 35px;
  font-weight: 500;
}

.facilityImage {
  width: 17px;
  height: 17px;
  margin-right: 15px;
}

.groupsFourRows {
  display: flex;
  flex-wrap: wrap;

  & .facilityItem {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 2px 0;
  }

  @media (--viewportSmall) {
    & .facilityItem {
      width: 33.3333333%;
    }
  }

  @media (--viewportMLarge) {
    & .facilityItem {
      width: 25%;
    }
  }
}

.amenitiesWrapper,
.extrasWrapper,
.facilitiesWrapper {
  padding: 0 24px 40px 24px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    padding: 0 0 40px;
  }
}

.extrasItemsWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.extraItem {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.extraItemTextInfo {
  display: flex;
  flex-direction: column;
  font-weight: 18px;
}

.extraItemImg {
  width: 20px;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
}

.extraItem:not(:first-child):not(:nth-child(2)) {
  margin-top: 20px;
}

.amenitiesItemsWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.amenityItem {
  display: flex;
  align-items: center;
  width: 100%;

  @media (--viewportMedium) {
    width: 33.33333%;
  }
}

.amenityItemText {
  display: flex;
  align-items: center;
}

.amenityItemIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.optionlabelFree {
  margin-left: 20px;
  margin-right: 5px;
  padding: 0;
  font-size: 13px;
  line-height: 17px;
  color: var(--successColor);
  position: relative;
}

.optionlabelFree:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 2px;
  top: 8px;
  left: -11px;
  transform: rotate(-45deg);
  background-color: var(--successColor);
}

.optionlabelFree:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 6px;
  top: 8px;
  left: -13px;
  transform: rotate(-45deg);
  background-color: var(--successColor);
}

.createContainer,
.createContainerCenter {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.createContainerCenter {
  align-self: center;
}

.buttonCreate {
  background: transparent;
  color: var(--successColor);
  border: 1px solid var(--successColor);
  padding: 8px 20px;
}

.submitButtonNext {
  margin: 70px 0 0;
  width: 250px;
}

.buttonEdit {
  padding: 5px 10px;
  font-size: 16px;
  min-height: unset;
  display: none;

  @media (--viewportForCard) {
    display: inline-block;
  }
}

.buttonEditMobile {
  padding: 5px 10px;
  font-size: 16px;
  min-height: unset;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;

  @media (--viewportForCard) {
    display: none;
  }
}

.spaceCardContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 0 0 30px;

  @media (min-width: 660px) {
    flex-direction: row;
    flex-basis: 50%;
  }

  @media (--viewportForCard) {
    flex-direction: column;
    flex-basis: 100%;
  }
}

.rootSpacescard,
.rootSpacescardSelected {
  flex-grow: 1;
  /* width: 300px; */
  height: auto;
  display: flex;
  flex-direction: column;
  /* padding: 0 7px 10px; */
  margin: 20px 24px 0;
  border: 0.5px solid #1d5c797a;
  border-radius: 0px 20px 20px 0px;
  display: flex;

  @media (--viewportMedium) {
    margin: 20px 0 0;
  }

  @media (--viewportLarge) {
    width: 100%;
  }
}

.rootSpacescardSelected {
  border: 0.5px solid #1d5c79;
}

.childrenInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 0 0 24px 0;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 0;
  }
}

.imageSection {
  width: 100%;
  max-width: 281px;
}

.imageContainer {
  width: 100%;
  height: 150px;
  max-height: 150px;
  background-color: var(--matterColorAnti);
  position: relative;

  @media (--viewportForCard) {
    max-width: 281px;
    display: flex;
  }
}

.imagePriceContainer {
  background-color: #1d5c79;
  border-radius: 0px 0px 20px 0px;
  color: var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.priceImageWrapper {
  display: flex;
  background-color: #68098b;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: 0 10px 0 0;
  align-items: center;
  justify-content: center;
}

.cardPrice {
  margin: 0 0 0 10px;
  font-size: 20px;
  line-height: 25px;
}

.iconPrice {
  width: 65%;
  object-fit: cover;
}

.listingImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sizeContainer,
.optionsContainer {
  width: 100%;
  padding: 5px 0px;
  display: flex;
  flex-direction: column;

  @media (--viewportForCard) {
    padding: 5px 20px;
  }
}

.sizeContainer {
  margin-left: 27px;
  display: flex;
  padding: 5px 0 0;
}

.listingTitle {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 23px;
}

.daylightContainer {
  display: flex;
  flex-direction: row;
  padding: 0 0 10px;
  align-items: center;
}

.daylightImage {
  width: 16px;
  height: 16px;
}

.listingDaylight {
  margin: 0;
  font-size: 10px;
  line-height: 13px;
  padding: 0 0 0 6px;
}

.listingSize {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  padding: 0 0 15px;
  color: var(--customBlue);
}

.listingStylesWrapper {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.listingStylesContainer {
  display: flex;
  flex-direction: column;
  padding: 0 6px 0 0;

  @media (--viewportForCard) {
    padding: 0 15px 0 0;
  }
}

.listingStylesImage {
  width: 25px;
  height: 25px;
}

.listingStyleNumber {
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-align: center;
}

.optionsContainer {
  display: flex;
  flex-direction: column;

  @media (--viewportForCard) {
    padding: 5px 0;
  }
}

.optionWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 10px;
}

.optionImageContainer {
  width: 16px;
  height: 16px;
  margin: 0 10px 0 0;
  display: flex;
}

.listingOptionImage {
  width: 100%;
  height: 100%;
}

.optionlabel {
  margin: 0;
  padding: 0 20px 0 0;
  font-size: 15px;
  line-height: 19px;
}

.optionlabelSpaceCardFree {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 17px;
  color: var(--successColor);
  position: relative;
}

.optionlabelSpaceCardFree:before {
  content: '';
  position: absolute;
  width: 9px;
  height: 2px;
  top: 8px;
  left: -11px;
  transform: rotate(-45deg);
  background-color: var(--successColor);
}

.optionlabelSpaceCardFree:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 6px;
  top: 8px;
  left: -13px;
  transform: rotate(-45deg);
  background-color: var(--successColor);
}

.checkedButton,
.buttonForSelect {
  display: flex;
  width: 62px;
  background-color: #1d5c79;
  align-items: center;
  border-radius: 0 18px 18px 0;
  border: none;
  cursor: pointer;
}

.buttonForSelect {
  opacity: 0.46;
}

.circle,
.circleChecked {
  border-radius: 50%;
  background-color: #fff;
  width: 34px;
  height: 34px;
  position: relative;
  margin: 0 auto;
}

.circleChecked:before {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  height: 10px;
  width: 14px;
  border-left: 3px solid #1d5c79;
  border-bottom: 3px solid #1d5c79;
  transform: rotate(-45deg);
}

.listingSpaceType {
  margin: 0;
  padding: 0 0 10px;
  font-size: 12px;
  line-height: 19px;
  color: var(--matterColor);
}

.facilitiesText {
  margin: 0;
  padding: 0;
}

.draftContainer {
  position: absolute;
  bottom: 5px;
  background-color: var(--customGrayTransparent);
  padding: 5px;
  width: 100%;
}

.draftText {
  margin: 0;
  text-align: center;
  color: var(--customDarkBlue);
}

.package {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  gap: 12px;
}

.packageTitle {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  min-width: 140px;
}

.packageDetails {
  font-size: 13px;
  color: #666;
}

/* .packagePrice {
  font-size: 14px;
  font-weight: bold;
  color: #333;
} */
