.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 14px;

  @media (--viewportLarge) {
    margin-bottom: 0px;
    padding: 1px 0 7px 0;
  }
}

.priceHint {
  font-size: 14px;
  line-height: 56px;
}
