@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 12px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  display: none;
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  padding: 12px 0 12px;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
  }
}

.fieldSelect {
  flex-grow: 1;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.fieldSelect > label {
  @apply --marketplaceCustomLabel;
}

.select {
  position: relative;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;
  border-bottom-width: 2px;

  @media (--viewportMedium) {
    border-bottom-width: 3px;
  }
}

.safeSpacingSwitchHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 25px 24px 20px;

  @media (--viewportMedium) {
    padding: 25px 0 20px;
  }
}

.meetSafeContainer {
  margin: 0;
  padding: 0 10px 0 16px;
  color: var(--customGray);
}

.submitButton {
  font-size: 21px;
  min-height: 65px;
}

.duration {
  margin-bottom: 32px;
}

.durationTitle {
  margin-right: 14px;
  font: normal normal normal 14px/20px IBM Plex Sans;
}

.durationCounter {
  font: normal normal 600 21px/32px IBM Plex Sans;
}
