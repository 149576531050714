@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: block;
  background: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='451.847px' height='451.847px' viewBox='0 0 451.847 451.847' style='enable-background:new 0 0 451.847 451.847;' xml:space='preserve'%3E%3Cg%3E%3Cpath stroke='%23ffffff' fill='%23ffffff' d='M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751 c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0 c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z'/%3E%3C/g%3E%3C/svg%3E%0A")
    no-repeat center;
  background-size: 12px;
  background-position: 44px;
  margin-left: 0px;

  @media (--viewportMedium) {
    background-position: right;
    margin-left: 12px;
  }
}

.languageButton {
  padding: 0 40px 0 0px;
  text-transform: uppercase;

  @media (--viewportMedium) {
    padding: 0 25px 0 8px;
  }
}

.languagesOptions {
  max-width: 56px;
  top: 42px;

  @media (--viewportMedium) {
    top: auto;
  }
}

.languageOption {
  padding: 10px;
  cursor: pointer;
}

.flagImg {
  width: 30px;
  height: 27px;
}
